import $ from 'jquery';
import merge from 'lodash/merge';
import bind from 'lodash/bind';

class LanguageSelectorComponent {
  constructor() {}

  static get defaults() {
    return Object.freeze({
      selector: '.language-selector',
      actions: {}
    });
  }

  _handleChange(event) {
    let $select = $(event.currentTarget);
    let href    = $select.val();

    window.location.href = href;
  }

  _setDomDependentOptions() {
    this.options.$scope = $(this.options.selector);
  }

  _attach() {
    let { $scope } = this.options;

    $scope.on('change.language-selector:change', bind(this._handleChange, this));
  }

  init(options = {}) {
    this.options = merge({}, this.constructor.defaults, options);

    this._setDomDependentOptions();
    this._attach();
  }
}

export default LanguageSelectorComponent;
