import bind from 'lodash/bind';
import extend from 'lodash/extend';
import ApplicationController from 'core/controllers/application';
import ScrollSpyComponent from 'components/scroll_spy';
import SearchInputComponent from 'components/search_input';
import DfpComponent from 'components/dfp';
import ButtonComponent from 'components/button';
class HomepageIndexController extends ApplicationController {
  constructor(options = {}) {
    super(options);

    extend(this.components, {
      scrollSpy: new ScrollSpyComponent(),
      searchInput: new SearchInputComponent(),
      dfp: new DfpComponent(),
      button: new ButtonComponent(),
    });
  }

  handleSearchInputScrollIn() {
    this.components.coreTopBar.openSearchInput();
    this.components.coreTopBar.removeTransparency();
  }

  handleSearchInputScrollOut() {
    this.components.coreTopBar.closeSearchInput();
    this.components.coreTopBar.addTransparency();
  }

  init() {
    super.init();

    let {
      scrollSpy,
      searchInput,
      dfp,
      button
    } = this.components;

    scrollSpy.init({
      selector: '#homepage-index__hero-search-input',
      actions: {
        onScrollIn: bind(this.handleSearchInputScrollIn, this),
        onScrollOut: bind(this.handleSearchInputScrollOut, this)
      }
    });
    searchInput.init({ selector: '#homepage-index__hero-search-input' });
    dfp.init();
    button.init({
      selector: '.search-input__submit',
      actions: {
        onClick: bind(button.setLoadingState, button)
      }
    });
  }
}

export default HomepageIndexController;
