import extend from 'lodash/extend';
import ApplicationController from 'core/controllers/application';
import SearchInputComponent from 'components/search_input';
import DfpComponent from 'components/dfp';
import AccordionComponent from 'components/accordion';

class SearchIndexController extends ApplicationController {
  constructor(options = {}) {
    super(options);

    extend(this.components, {
      accordion: new AccordionComponent(),
      searchInput: new SearchInputComponent(),
      dfp: new DfpComponent()
    });
  }

  init() {
    super.init();

    this.components.accordion.init({ scrollToOnExpand: false });
    this.components.searchInput.init({ selector: '.search-index__sidebar__search-input' });
    this.components.dfp.init();
  }
}

export default SearchIndexController;
