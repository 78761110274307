import HomepageIndexController from 'core/controllers/homepage_index';
import HomepageHomeawayController from 'core/controllers/homepage_homeaway';
import RegionsMapsShowController from 'core/controllers/regions_maps_show';
import RegionsShowController from 'core/controllers/regions_show';
import ResortsProductsShowController from 'core/controllers/resorts_products_show';
import ResortsShowController from 'core/controllers/resorts_show';
import ResortsTrailMapsIndexController from 'core/controllers/resorts_trail_maps_index';
import SearchIndexController from 'core/controllers/search_index';
import StaticAffiliateController from 'core/controllers/search_index';
import StaticContactUsController from 'core/controllers/static_contact_us';
import StaticErrorController from 'core/controllers/static_error';
import StaticPrivacyStatementController from 'core/controllers/static_privacy_statement';
import StaticStyleguideController from 'core/controllers/static_styleguide';
import StaticTermsOfUseController from 'core/controllers/static_terms_of_use';
import ApplicationController from 'core/controllers/application';
import GiftCardsNewController from 'core/controllers/gift_cards_new';

let Controllers = {
  HomepageIndexController,
  HomepageHomeawayController,
  RegionsMapsShowController,
  RegionsShowController,
  ResortsProductsShowController,
  ResortsShowController,
  ResortsTrailMapsIndexController,
  SearchIndexController,
  StaticAffiliateController,
  StaticContactUsController,
  StaticErrorController,
  StaticPrivacyStatementController,
  StaticStyleguideController,
  StaticTermsOfUseController,
  GiftCardsNewController,
};

export function getClassByName(controllerName) {
  return Controllers[controllerName] || ApplicationController;
}
