const keyCodes = {
  TAB: 9,
  ENTER: 13,
  SHIFT: 16,
  OPTION_ALT: 18,
  PAUSE_BREAK: 19,
  CAPS_LOCK: 20,
  ESC: 27,
  PAGE_UP: 33,
  PAGE_DOWN: 34,
  END: 35,
  HOME: 36,
  LEFT_ARROW: 37,
  UP_ARROW: 38,
  RIGHT_ARROW: 39,
  DOWN_ARROW: 40,
  PRINT_SCREEN: 44,
  INSERT: 45,
  LEFT_CMD_START: 91,
  RIGHT_CMD_START: 93,
  F1: 112,
  F2: 113,
  F3: 114,
  F4: 115,
  F5: 116,
  F6: 117,
  F7: 118,
  F8: 119,
  F9: 120,
  F10: 121,
  F11: 122,
  F12: 123,
  F13: 124,
  F14: 125,
  F15: 126,
  F16: 127,
  F17: 128,
  F18: 129,
  F19: 130
};

let KeyCodeUtil = {
  isCharacter(keyCode) {
    if (!keyCode) { return false; }

    return !this._testKeyCodeType(keyCode);
  },

  isControl(keyCode) {
    if (!keyCode) { return false; }

    return this._testKeyCodeType(keyCode);
  },

  isEqual(keyCode, keyName) {
    return keyCode === keyCodes[keyName];
  },

  getCodeFromName(keyName) {
    return keyCodes[keyName];
  },

  _testKeyCodeType(keyCode) {
    return (
      (keyCode === keyCodes.TAB)
      || (keyCode >= keyCodes.SHIFT && keyCode <= keyCodes.CAPS_LOCK)
      || (keyCode === keyCodes.ESC)
      || (keyCode >= keyCodes.PAGE_UP && keyCode <= keyCodes.DOWN_ARROW)
      || (keyCode === keyCodes.PRINT_SCREEN)
      || (keyCode === keyCodes.INSERT)
      || (keyCode === keyCodes.ENTER)
      || (keyCode === keyCodes.LEFT_CMD_START)
      || (keyCode === keyCodes.RIGHT_CMD_START)
      || (keyCode >= keyCodes.F1 && keyCode <= keyCodes.F19)
    );
  }
};

export default KeyCodeUtil;
