import $ from 'jquery';
import bind from 'lodash/bind';
import merge from 'lodash/merge';
import throttle from 'lodash/throttle';

class ScrollSpyComponent {
  constructor() {}

  static get defaults() {
    return Object.freeze({
      selector: '.scroll-spy',
      throttleDelay: 200,
      actions: {
        onScrollIn: function() {},
        onScrollOut: function() {}
      }
    });
  }

  evaluateOffset() {
    let scrollTop = this.options.$window.scrollTop();
    let offsetTop = this.options.$scope.offset().top;

    if (scrollTop >= offsetTop) {
      this.options.actions.onScrollIn();
    } else {
      this.options.actions.onScrollOut();
    }
  }

  setDomDependentOptions() {
    this.options.$window = $(window);
    this.options.$scope  = $(this.options.selector);
  }

  attach() {
    this.options.$window.on(
      'scroll.scroll-spy:evaluate-offset',
      throttle(
        bind(this.evaluateOffset, this),
        this.options.throttleDelay
      )
    );
  }

  init(options = {}) {
    this.options = merge({}, this.constructor.defaults, options);

    this.setDomDependentOptions();
    this.attach();
  }
}

export default ScrollSpyComponent;
