import $ from 'jquery';
import bind from 'lodash/bind';
import merge from 'lodash/merge';
import AlertComponent from 'components/alert';

class CloudstoreTopBarComponent {
  constructor() {
    this.components = {
      alert: new AlertComponent()
    };
  }

  static get defaults() {
    return Object.freeze({
      selector: '.cloudstore-top-bar',
      classes: {
        dropdown: 'cloudstore-top-bar__dropdown',
        dropdownOpen: 'cloudstore-top-bar__dropdown--open',
        dropdownTrigger: 'cloudstore-top-bar__dropdown__trigger',
        stopPropagation: 'cloudstore-top-bar--stop-propagation',
        alert: 'cloudstore-top-bar__error__alert',
        errorDismissed: 'cloudstore-top-bar--error-dismissed'
      }
    });
  }

  setDomDependentOptions() {
    this.options.$document = $(document);
    this.options.$window   = $(window);
    this.options.$scope    = $(this.options.selector);
  }

  _hideGlobalError() {
    let { $scope, classes } = this.options;

    $scope.addClass(classes.errorDismissed);
  }

  _initAlert() {
    let { classes } = this.options;

    this.components.alert.init({
      selector: `.${classes.alert}`,
      actions: {
        onDismiss: bind(this._hideGlobalError, this)
      }
    });
  }

  closeDropdowns() {
    $(`.${this.options.classes.dropdown}`).removeClass(this.options.classes.dropdownOpen);
  }

  toggleDropdown(event) {
    event.stopImmediatePropagation();

    let $dropdown = $(event.currentTarget);
    let isOpen    = $dropdown.hasClass(this.options.classes.dropdownOpen);

    this.closeDropdowns();

    if (!isOpen) {
      $dropdown.addClass(this.options.classes.dropdownOpen);
    }
  }

  preventTriggerDefault(event) {
    event.preventDefault();
  }

  stopPropagation(event) {
    event.stopImmediatePropagation();
  }

  attach() {
    let {
      $document,
      $scope,
      classes
    } = this.options;

    $document.on(
      'click.cloudstore-top-bar:close-dropdowns',
      bind(this.closeDropdowns, this)
    );

    $scope.on(
      'click.cloudstore-top-bar:stop-propagation',
      `.${classes.stopPropagation}`,
      bind(this.stopPropagation, this)
    );

    $scope.on(
      'click.cloudstore-top-bar:toggle-dropdown',
      `.${classes.dropdown}`,
      bind(this.toggleDropdown, this)
    );

    $scope.on(
      'click.cloudstore-top-bar:prevent-trigger-default',
      `.${classes.dropdownTrigger}`,
      bind(this.preventTriggerDefault, this)
    );
  }

  init(options = {}) {
    this.options = merge({}, this.constructor.defaults, options);

    this.setDomDependentOptions();
    this._initAlert();
    this.attach();
  }
}

export default CloudstoreTopBarComponent;
