import merge from 'lodash/merge';

class GiftCardAmountSelector {

  static get defaults() {
    return Object.freeze({
      selector: '.giftcard-amount-selector',
      classes: {
        customAmount: 'giftcard_amount_custom_input',
        amountOptions: 'giftcard-amount-selector__user-input',
        buttonAmount: 'giftcard__submit-button__amount',
        hiddenInput: 'giftcard-amount-selector__hidden_input',
        optionButton: 'amount-selector__button',
        customInputActive: 'custom-amount--active',
        bonusAlert: 'giftcards__form-container__promo-content-alert',
        bonusAmount: 'giftcards__form-container__promo-content-alert__bonus'
      },
    });
  }

  _uncheckAllRadios() {
    const { amountOptions } = this.options;

    for (let i = 0; i < amountOptions.length; i++) {
      amountOptions[i].checked = false;
    }
  }

  _bonusAlertOn(val) {
    const { bonusAlert, bonusAmount } = this.options;

    if (bonusAlert) {
      bonusAlert.style.display = 'block';
      bonusAmount && val ? bonusAmount.innerHTML = val : null;
    }
  }

  _bonusAlertOff() {
    const { bonusAlert } = this.options;

    if (bonusAlert) { bonusAlert.style.display = 'none'; }
  }

  _customAmountOn() {
    const { customAmount, classes } = this.options;
    customAmount.parentNode.classList.add(classes.customInputActive);
    this._bonusAlertOff();
  }

  _customAmountOff() {
    const { customAmount, classes } = this.options;
    customAmount.parentNode.classList.remove(classes.customInputActive);
    this._bonusAlertOn();
  }

  _updateButton(value) {
    this.options.buttonAmount.innerText = value
      ? '$' + value
      : '';
  }

  _trimCustomInput() {
    const { customAmount } = this.options;

    if (`${customAmount.value}`.length > 3) {
      customAmount.value = customAmount.value.slice(0, 3);
    }
  }

  _checkIfPresetAmount() {
    const { customAmount, amountOptions } = this.options;
    const radioOptions = amountOptions.filter(option => option.type == 'radio');

    for (var i = 0; i < radioOptions.length; i++) {
      let option = radioOptions[i];

      if (parseInt(customAmount.value) >= parseInt(option.value)) {
        this._bonusAlertOn(option.dataset.promo);
      }
    }
  }

  _handleCustomInput() {
    const { hiddenInput, customAmount, amountOptions } = this.options;

    if (customAmount.value && customAmount.value > 0) {
      this._uncheckAllRadios();

      customAmount.value = parseInt(customAmount.value);
      this._customAmountOn();
      this._trimCustomInput();
      hiddenInput.value = customAmount.value;
      this._updateButton(hiddenInput.value);

      this._checkIfPresetAmount();
    } else {
      amountOptions[1].checked = true;
      hiddenInput.value = amountOptions[1].value;
      this._updateButton(hiddenInput.value);
      this._customAmountOff();
    }
  }

  _handleRadioInput(radioNode) {
    const { hiddenInput, customAmount } = this.options;

    customAmount.value = '';
    customAmount.required = false;
    this._customAmountOff();
    hiddenInput.value = radioNode.value;
    this._updateButton(hiddenInput.value);
    this._bonusAlertOn(radioNode.dataset.promo);
  }

  _handleAmountChange(inputNode) {
    inputNode == this.options.customAmount
      ? this._handleCustomInput()
      : this._handleRadioInput(inputNode);
  }

  _attach() {
    const { amountOptions, hiddenInput, customAmount } = this.options;

    for (let i = 0; i < amountOptions.length; i++) {
      const input = amountOptions[i];
      const eventType = input.type == 'radio' ? 'change' : 'input';
      input.addEventListener(eventType, (e) => this._handleAmountChange(e.target));
    }

    customAmount.addEventListener('click', () => {
      this._customAmountOn();
      this._uncheckAllRadios();
      customAmount.required = true;
      hiddenInput.value     = '';
      customAmount.value    = '';
      this._updateButton(customAmount.value);
    });
  }

  _setDomDependentOptions() {
    this.options.scope = document.querySelector(this.options.selector);

    const { scope, classes } = this.options;
    this.options.buttonAmount   = document.querySelector(`.${classes.buttonAmount}`);
    this.options.amountOptions  = Array.from(scope.querySelectorAll(`.${classes.amountOptions}`));
    this.options.hiddenInput    = scope.querySelector(`.${classes.hiddenInput}`);
    this.options.customAmount   = scope.querySelector(`.${classes.customAmount}`);
    this.options.bonusAlert     = document.querySelector(`.${classes.bonusAlert}`);
    this.options.bonusAmount    = document.querySelector(`.${classes.bonusAmount}`);

    this._updateButton(this.options.hiddenInput.value);
  }

  init(options = {}) {
    this.options = merge({}, this.constructor.defaults, options);
    this._setDomDependentOptions();
    this._attach();
  }
}

export default GiftCardAmountSelector;
