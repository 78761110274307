import merge from 'lodash/merge';
import bind from 'lodash/bind';
import each from 'lodash/each';
import LoginModalComponent from 'components/login_modal';
import SignUpModalComponent from 'components/sign_up_modal';
import ForgotPasswordModalComponent from 'components/forgot_password_modal';

class AuthFlowComponent {
  constructor() {
    this.components = {
      loginModal: new LoginModalComponent(),
      signUpModal: new SignUpModalComponent(),
      forgotPasswordModal: new ForgotPasswordModalComponent()
    };
  }

  static get defaults() {
    return Object.freeze({
      selector: '.auth-flow',
      actions: {}
    });
  }

  _closeModals() {
    each(this.components, (modal) => {
      modal.close();
    });
  }

  init(options = {}) {
    this.options = merge({}, this.constructor.defaults, options);

    this.components.loginModal.init({
      actions: {
        onOpen: bind(this._closeModals, this)
      }
    });
    this.components.signUpModal.init({
      actions: {
        onOpen: bind(this._closeModals, this)
      }
    });
    this.components.forgotPasswordModal.init({
      actions: {
        onOpen: bind(this._closeModals, this)
      }
    });
  }
}

export default AuthFlowComponent;
