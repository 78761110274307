import $ from 'jquery';
import bind from 'lodash/bind';
import merge from 'lodash/merge';
import SearchInputComponent from 'components/search_input';
import AlertComponent from 'components/alert';
import AlertNewSkinComponent from 'components/alert_new_skin';
import MediaQuery from 'utils/media_query';

class CoreTopBarComponent {
  constructor() {
    this.components = {
      searchInput: new SearchInputComponent(),
      alert: new AlertComponent(),
      alertNewSkin: new AlertNewSkinComponent()
    };

    this.autoBindPublicMethods();
  }

  static get defaults() {
    return Object.freeze({
      selector: '.core-top-bar',
      hideSearchInput: false,
      classes: {
        dropdown: 'core-top-bar__dropdown',
        dropdownOpen: 'core-top-bar__dropdown--open',
        dropdownTrigger: 'core-top-bar__dropdown__trigger',
        dropdownSearchInput: 'core-top-bar__dropdown--sic',
        transparent: 'core-top-bar--transparent',
        searchInput: 'core-top-bar__search-input',
        stopPropagation: 'core-top-bar--stop-propagation',
        alert: 'core-top-bar__error__alert',
        errorDismissed: 'core-top-bar--error-dismissed'
      }
    });
  }

  autoBindPublicMethods() {
    bind(this.addTransparency, this);
    bind(this.removeTransparency, this);
    bind(this.openSearchInput, this);
    bind(this.closeSearchInput, this);
  }

  _hideGlobalError() {
    let { $scope, classes } = this.options;

    $scope.addClass(classes.errorDismissed);
  }

  _initAlert() {
    let { classes } = this.options;

    this.components.alert.init({
      selector: `.${classes.alert}`,
      actions: {
        onDismiss: bind(this._hideGlobalError, this)
      }
    });
  }

  _initAlertNewSkin() {
    this.components.alertNewSkin.init({
      actions: {
        onDismiss: bind(this._hideGlobalError, this)
      }
    });
  }

  addTransparency() {
    this.options.$scope.addClass(this.options.classes.transparent);
  }

  removeTransparency() {
    this.options.$scope.removeClass(this.options.classes.transparent);
  }

  openSearchInput() {
    let shouldOpenSearchInput = MediaQuery.isAtLeast('medium');

    if (shouldOpenSearchInput) {
      $(`.${this.options.classes.dropdownSearchInput}`).addClass(this.options.classes.dropdownOpen);
    }
  }

  closeSearchInput() {
    $(`.${this.options.classes.dropdownSearchInput}`).removeClass(this.options.classes.dropdownOpen);
  }

  initSearchInput() {
    if (!this.options.hideSearchInput) {
      this.components.searchInput.init({ selector: '.' + this.options.classes.searchInput });
    }
  }

  setState() {
    $(function() {
      $(this).trigger('scroll');
    });
  }

  setDomDependentOptions() {
    this.options.$document = $(document);
    this.options.$window   = $(window);
    this.options.$scope    = $(this.options.selector);
  }

  closeDropdowns() {
    $(`.${this.options.classes.dropdown}`).removeClass(this.options.classes.dropdownOpen);
  }

  toggleDropdown(event) {
    event.stopImmediatePropagation();

    let $dropdown = $(event.currentTarget);
    let isOpen    = $dropdown.hasClass(this.options.classes.dropdownOpen);

    this.closeDropdowns();

    if (!isOpen) {
      $dropdown.addClass(this.options.classes.dropdownOpen);
    }
  }

  preventTriggerDefault(event) {
    event.preventDefault();
  }

  stopPropagation(event) {
    event.stopImmediatePropagation();
  }

  attach() {
    let {
      $document,
      $scope,
      classes
    } = this.options;

    $document.on(
      'click.core-top-bar:close-dropdowns',
      bind(this.closeDropdowns, this)
    );

    $scope.on(
      'click.core-top-bar:stop-propagation',
      `.${classes.stopPropagation}`,
      bind(this.stopPropagation, this)
    );

    $scope.on(
      'click.core-top-bar:toggle-dropdown',
      `.${classes.dropdown}`,
      bind(this.toggleDropdown, this)
    );

    $scope.on(
      'click.core-top-bar:prevent-trigger-default',
      `.${classes.dropdownTrigger}`,
      bind(this.preventTriggerDefault, this)
    );
  }

  init(options = {}) {
    this.options = merge({}, this.constructor.defaults, options);

    this.setDomDependentOptions();
    this.attach();
    this.initSearchInput();
    this._initAlert();
    this._initAlertNewSkin();
    this.setState();
  }
}

export default CoreTopBarComponent;
