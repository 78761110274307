import merge from 'lodash/merge';

class PhotosComponent {
  constructor() {}

  static get defaults() {
    return Object.freeze({
      selector: '.photos',
      slideMargin: 16,
      displacement: {
        current: 0,
        min: 0,
        max: 0,
        step: 500,
        index: 0,
      },
      classes: {
        contained: 'photos__carousel--contained',
        slideActive: 'photos__carousel__photo--active',
        slidingList: 'photos__carousel__photo-list',
        listItem: 'photos__carousel__photo',
        prevButton: 'photos__carousel-nav-button--left',
        nextButton: 'photos__carousel-nav-button--right',
        navButtonHidden: 'photos__carousel-nav-button--hidden',
      },
    });
  }

  _calcDisplacementSettings() {
    const { contained, scope, listItems, slideMargin, displacement } = this.options;

    if (contained) {

      displacement.max    = listItems.length - 1;

    } else {

      const parentWidth   = scope.offsetWidth;
      const slideWidth    = listItems[0].offsetWidth + slideMargin;
      const numSlides     = listItems.length;

      displacement.max    = (slideWidth * numSlides) - parentWidth;
      displacement.step   = Math.max(slideWidth * 1.5, parentWidth - slideWidth);

    }

    this._updateButtonVisibility();
  }

  _swapFeaturedPhoto(direction) {
    const { displacement, listItems, classes } = this.options;

    displacement.current = (displacement.current + direction > listItems.length - 1)
      ? listItems.length - 1
      : displacement.current + direction;

    [].forEach.call(listItems, (s, i) => i == displacement.current ? s.classList.add(classes.slideActive) : s.classList.remove(classes.slideActive));
    this._updateButtonVisibility();
  }

  _slidePhotoList(direction) {
    const { displacement } = this.options;
    const limit = direction === 1 ? 'max' : 'min';

    if (displacement.current === displacement[limit]) return;

    displacement.current += displacement.step * direction;
    displacement.current = Math.min(displacement.current * direction, displacement[limit]) * direction;
    this.options.slidingList.style.transform = `translate(-${displacement.current}px)`;
    this._updateButtonVisibility();
  }

  _updateButtonVisibility() {
    const { displacement, prevButton, nextButton, classes } = this.options;

    const minPosition = displacement.current === displacement.min;
    const maxPosition = displacement.current >= displacement.max;

    minPosition
      ? prevButton.classList.add(classes.navButtonHidden)
      : prevButton.classList.remove(classes.navButtonHidden);

    maxPosition
      ? nextButton.classList.add(classes.navButtonHidden)
      : nextButton.classList.remove(classes.navButtonHidden);
  }

  attach() {
    if (!this.options.scope) return;

    const handleButtonClick = this.options.contained ? this._swapFeaturedPhoto : this._slidePhotoList;

    this.options.prevButton.onclick = handleButtonClick.bind(this, -1);
    this.options.nextButton.onclick = handleButtonClick.bind(this, 1);
    window.addEventListener('resize', this._calcDisplacementSettings.bind(this));
  }

  _setDomDependentOptions() {
    const { selector, classes } = this.options;

    this.options.scope = document.querySelector(selector);
    if (!this.options.scope) return;

    this.options.slidingList = this.options.scope.querySelector(`.${classes.slidingList}`);
    this.options.listItems   = this.options.scope.querySelectorAll(`.${classes.listItem}`);
    this.options.prevButton  = this.options.scope.querySelector(`.${classes.prevButton}`);
    this.options.nextButton  = this.options.scope.querySelector(`.${classes.nextButton}`);
    this.options.contained   = this.options.scope.classList.contains(classes.contained);

    this._calcDisplacementSettings();
    this.options.listItems[this.options.displacement.current].classList.add(classes.slideActive);
  }

  init(options) {
    this.options = merge({}, this.constructor.defaults, options);

    this._setDomDependentOptions();
    this.attach();

    return this;
  }
}

export default PhotosComponent;
