import extend from 'lodash/extend';
import ApplicationController from 'core/controllers/application';
import JumplinksComponent from 'components/jumplinks';
import ContentRevealerComponent from 'components/content_revealer';
import AmenitiesComponent from 'components/amenities';
import PhotosComponent from 'components/photos';
import ImageGalleryComponent from 'components/image_gallery';
import DfpComponent from 'components/dfp';
import SearchInputComponent from 'components/search_input';

class ResortsShowController extends ApplicationController {
  constructor(options = {}) {
    super(options);

    extend(this.components, {
      jumplinks: new JumplinksComponent(),
      contentRevealer: new ContentRevealerComponent(),
      amenities: new AmenitiesComponent(),
      photos: new PhotosComponent(),
      imageGallery: new ImageGalleryComponent(),
      dfp: new DfpComponent(),
      searchInput: new SearchInputComponent()
    });
  }

  init() {
    super.init();

    this.components.jumplinks.init();
    this.components.contentRevealer.init();
    this.components.amenities.init();
    this.components.photos.init();
    this.components.imageGallery.init({ selector: '.photos__carousel' });
    this.components.searchInput.init({ selector: '.resorts-show__sidebar__search-input' });
    this.components.dfp.init();
  }
}

export default ResortsShowController;
