import merge from 'lodash/merge';
import i18n from 'utils/i18n';
import { CSS } from 'utils/constants.js.erb';

class GiftCardStripeComponent {

  static get defaults() {
    return Object.freeze({
      selector: '.gc-stripe-card-element',
      tokenNames: {
        default: 'stripe_pub_key',
        usd: 'stripe_usd_key',
        cad: 'stripe_cad_key',
      },
      stripe_options: {
        card_options: {
          style: {
            base: {
              color: CSS.colors.slate9,
              fontSize: CSS.typography.baseFontSize,
              fontFamily: CSS.typography.baseFontFamily,
              fontSmoothing: 'antialiased',
            },
            invalid: {
              color: CSS.colors.red10,
              iconColor: CSS.colors.red10,
            }
          },
          iconStyle: 'solid',
        },
      },
      classes: {
        cardRoot: 'giftcards-payment-form__card-root',
      }
    });
  }

  _destroyStripeCard() {
    this.card ? this.card.destroy() : null;
  }

  _changeAPIKey(currencyCode) {
    currencyCode == 'CAD'
      ? this.options.currentStripeKey = this.options.stripeAPIKeyCAD
      : this.options.currentStripeKey = this.options.stripeAPIKeyUSD;

    this._destroyStripeCard();
    this._initStripe();
  }

  _initStripe() {
    this.stripe = window.Stripe(this.options.currentStripeKey);
    this.elements = this.stripe.elements({ locale: i18n.locale });
    this.card = this.elements.create('card', this.options.stripe_options.card_options);
    this.card.mount(`.${this.options.classes.cardRoot}`);
  }

  _setDomDependentOptions() {
    this.options.scope              = document.querySelector(this.options.selector);
    this.options.stripeAPIKeyUSD    = document.getElementsByName('stripe_usd_key')[0].content;
    this.options.stripeAPIKeyCAD    = document.getElementsByName('stripe_cad_key')[0].content;
    this.options.currentStripeKey   = this.options.stripeAPIKeyUSD;
  }

  init(options = {}) {
    this.options = merge({}, this.constructor.defaults, options);

    this._setDomDependentOptions();
    this._initStripe();
  }
}

export default GiftCardStripeComponent;
