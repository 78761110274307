import $ from 'jquery';
import createHistory from 'history/createBrowserHistory';
import URI from 'urijs';
import maxBy from 'lodash/maxBy';
import bind from 'lodash/bind';
import merge from 'lodash/merge';
import debounce from 'lodash/debounce';
import moment from 'moment';
import i18n from 'utils/i18n';
import DatePickerComponent from 'components/date_picker';
import MediaQuery from 'utils/media_query';
import RelatedProductsComponent from 'components/related_products';

class CalendarComponent {
  constructor() {
    this.components = {
      relatedProducts: new RelatedProductsComponent(),
      datePicker: new DatePickerComponent()
    };
  }

  static get defaults() {
    return Object.freeze({
      selector: '.calendar',
      endpoint: '',
      start_date: undefined,
      type: undefined,
      resort_slug: undefined,
      highlight: undefined,
      track: undefined,
      title: 'Buy Lift Tickets in Advance and Save | Liftopia',
      dateFormat: i18n.l('date.param'),
      debounceWaitTime: 250,
      classes: {
        calendarTable: 'calendar__table-container',
        day: 'calendar-day',
        content: 'calendar__content',
        loading: 'calendar--loading',
        dayDetails: 'calendar__selected-day-details',
        dayDetailsHeader: 'calendar__selected-day-details__header-section',
        dayDetailsCTA: 'calendar__selected-day-details__cta_container',
        ticketList: 'calendar__selected-day-details__ticket-types-list',
        ticketType: 'calendar__selected-day-details__ticket-types-list__type',
        navigationLink: 'calendar__navigation-link',
        datePicker: 'calendar__navigation-datepicker',
        ticketTypeToggleButton: 'calendar__ticket-type-toggle-button',
        ticketTypeToggleMenu: 'calendar__ticket-type-toggle-menu',
        redesign: 'calendar--redesign',
        core: 'calendar--core',
      },
      actions: {}
    });
  }

  _normalizeDayHeight() {
    let $days           = this.options.$scope.find(`.${this.options.classes.day}`);
    let shouldNormalize = MediaQuery.isAtLeast('medium');

    $days.css('height', 'auto');

    if (shouldNormalize) {
      let tallestDay = maxBy($days, function(day) { return $(day).height(); });
      let maxHeight  = $(tallestDay).height();

      $days.height(maxHeight);
    }
  }

  _normalizeSquareDay() {
    let $days     = this.options.$scope.find(`.${this.options.classes.day}`);
    let widestDay = maxBy($days, function(day) { return $(day).width(); });
    let maxWidth  = $(widestDay).width();
    $days.height(maxWidth);

    this._normalizeDayDetails();
  }

  _normalizeDayDetails() {
    const { $scope, classes } = this.options;

    const shouldNormalize   = MediaQuery.isAtLeast('medium');
    const $calTable         = $scope.find(`.${classes.calendarTable}`);
    const $dayDetails       = $scope.find(`.${classes.dayDetails}`);
    const $dayDetailsHeader = $dayDetails.find(`.${classes.dayDetailsHeader}`);
    const $dayDetailsCTA    = $dayDetails.find(`.${classes.dayDetailsCTA}`);
    const $ticketTypeList   = $dayDetails.find(`.${classes.ticketList}`);

    if (shouldNormalize) {
      const targetHeight  = $calTable.height() - $dayDetailsHeader.height() - $dayDetailsCTA.height() - parseInt($dayDetailsCTA.css('marginTop'));

      $ticketTypeList.height(targetHeight);
    }
  }

  _normalizeDaySize() {
    const { $scope, classes } = this.options;
    this.options.redesign = $scope.hasClass(classes.redesign);

    this.options.redesign
      ? this._normalizeSquareDay()
      : this._normalizeDayHeight();
  }


  _initRelatedProducts() {
    if (this.options.redesign && this.options.core) {
      this.components.relatedProducts.init({
        start_date: this.options.start_date,
      });
    }
  }

  _initDatePicker() {
    var minDateString = moment().subtract(1, 'day').format('YYYY-MM-DD'); // allow today-1 to account for TZ issues
    this.components.datePicker.init({
      selector: `.${this.options.classes.datePicker}`,
      widget: {
        defaultDate: moment(this.options.start_date).toDate(),
        setDefaultDate: true,
        minDate: moment(minDateString).toDate()
      },
      actions: {
        onSelect: bind(this._renderDateFromDatePicker, this)
      }
    });
  }

  _replaceHistoryState() {
    let uri   = new URI('');
    uri.addQuery({
      type: this.options.type,
      start_date: this.options.start_date
    });

    let originalUrlParams = new URLSearchParams(window.location.search);
    if (originalUrlParams.get('utm_campaign')) {
      uri.addQuery({utm_campaign: originalUrlParams.get('utm_campaign')});
    }
    if (originalUrlParams.get('utm_medium')) {
      uri.addQuery({utm_medium: originalUrlParams.get('utm_medium')});
    }
    if (originalUrlParams.get('utm_source')) {
      uri.addQuery({utm_source: originalUrlParams.get('utm_source')});
    }
    if (originalUrlParams.get('webSyncID')) {
      uri.addQuery({webSyncID: originalUrlParams.get('webSyncID')});
    }
    if (originalUrlParams.get('sessionGUID')) {
      uri.addQuery({sessionGUID: originalUrlParams.get('sessionGUID')});
    }
    if (originalUrlParams.get('_ga')) {
      uri.addQuery({_ga: originalUrlParams.get('_ga')});
    }

    let query = uri.toString();

    if (query.length) {
      if (!this.options.historyInstance) {
        this.options.historyInstance = createHistory();
      }

      this.options.historyInstance.replace({ search: query });
    }
  }

  _setDomDependentOptions() {
    this.options.$scope   = $(this.options.selector);
    this.options.core     = this.options.$scope.hasClass(this.options.classes.core);
    this.options.endpoint = this.options.$scope.data('endpoint') || this.options.endpoint;
    this.options.title    = $('title').text();
    this.options.ticketList = document.querySelector(`.${this.options.classes.ticketList}`);
  }

  _setQueryOptions() {
    let uri = new URI(this.options.endpoint);

    this.options.endpoint   = uri.pathname();
    this.options.type       = uri.query(true)['type'];
    this.options.start_date = uri.query(true)['start_date'];
  }

  _setPreloadState() {
    this.options.$scope.addClass(this.options.classes.loading);
  }

  _setPostloadState(data) {
    this.options.$scope.replaceWith(data);
    this._setup();

    if (window.Modernizr && window.Modernizr.history) {
      this._replaceHistoryState();
    }

    this.options.$scope.removeClass(this.options.classes.loading);
  }

  _renderDateFromDatePicker(date) {
    this.options.start_date = moment(date).format(this.options.dateFormat);
    this.options.highlight  = true;
    this.options.track      = true;

    this._render();

    this.options.highlight = undefined;
    this.options.track     = undefined;
  }

  _renderDateFromLink(event) {
    event.preventDefault();

    let href = $(event.currentTarget).attr('href');
    let uri  = new URI(href);

    this.options.start_date = uri.query(true)['start_date'] || '';
    this.options.resort_slug = uri.query(true)['resort_slug'] || '';
    this.options.highlight = uri.query(true)['highlight'];
    this.options.track = uri.query(true)['track'];


    this._render();
  }

  _handleTicketTypeButtonClick(event) {
    event.preventDefault();

    this._renderTicketType($(event.currentTarget).data('ticket-type'));
  }

  _handleTicketTypeSelect(event) {
    event.preventDefault();

    this._renderTicketType(event.currentTarget.value);
  }

  _renderTicketType(type) {
    this.options.type = type || '';
    this.options.highlight = false;
    this.options.track = false;
    this._render();
  }

  _render() {
    if (!this.options.endpoint) { return; }

    this._setPreloadState();

    $.ajax({
      url: this.options.endpoint,
      data: {
        start_date: this.options.start_date,
        type: this.options.type,
        resort_slug: this.options.resort_slug,
        highlight: this.options.highlight,
        track: this.options.track
      }
    }).then(bind(this._setPostloadState, this));

    if (this.options.redesign && this.options.core) {
      this.components.relatedProducts.render(this.options.start_date);
    }
  }

  _attach() {
    this.options.$scope.on(
      'click.calendar:date-link-click',
      `.${this.options.classes.navigationLink}`,
      bind(this._renderDateFromLink, this)
    );

    this.options.$scope.on(
      'click.calendar:ticket-type-button-click',
      `.${this.options.classes.ticketTypeToggleButton}`,
      bind(this._handleTicketTypeButtonClick, this)
    );

    this.options.$scope.on(
      'change.calendar:ticket-type-select',
      `.${this.options.classes.ticketTypeToggleMenu}`,
      bind(this._handleTicketTypeSelect, this)
    );

    $(window).on(
      'resize.calendar:resize-window',
      debounce(
        bind(this._normalizeDaySize, this),
        this.options.debounceWaitTime
      )
    );
  }

  _setup() {
    this._setDomDependentOptions();
    this._setQueryOptions();
    this._initDatePicker();
    this._normalizeDaySize();
    this._attach();
  }

  init(options) {
    this.options = merge({}, this.constructor.defaults, options);

    this._setup();
    this._initRelatedProducts();
    this._replaceHistoryState();
  }
}

export default CalendarComponent;
