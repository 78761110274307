/* global googletag */
import $ from 'jquery';
import sortedIndexBy from 'lodash/sortedIndexBy';
import sortBy from 'lodash/sortBy';
import first from 'lodash/first';
import last from 'lodash/last';
import each from 'lodash/each';
import merge from 'lodash/merge';
import bind from 'lodash/bind';
import MediaQuery from 'utils/media_query';

class DfpComponent {
  constructor() {}

  static get defaults() {
    return Object.freeze({
      adSlotSelector: '.ad-slot',
      actions: {}
    });
  }

  setDomDependentOptions() {
    this.options.$adSlots = $(this.options.adSlotSelector);
  }

  findCreativeDimensions(slotWidth, potentialCreativeDimensions = [[970,90], [728,90], [300,250]]) {
    /*
      The potential creative dimensions are configured by the marketing team
      in DFP.

      ex: [[potentialCreativeWidth, potentialCreativeHeight]]
    */

    /* Sort potentialCreativeDimensions by ascending width values */
    let sortedPotentialCreativeDimensions = sortBy(potentialCreativeDimensions, [
      function(arr) {
        return arr[0];
      }
    ]);
    let minCreativeWidth                  = first(sortedPotentialCreativeDimensions)[0];
    let maxCreativeWidth                  = last(sortedPotentialCreativeDimensions)[0];

    if (slotWidth < minCreativeWidth) {
      return false;
    } else if (slotWidth >= maxCreativeWidth) {
      return last(sortedPotentialCreativeDimensions);
    } else {
      /*
        Use binary search to determine appropriate index of
        potentialCreativeDimensions array that allows for creative to be injected
        into an ad slot without breaking out of its containing DOM node.

        sortedIndexBy docs: https://lodash.com/docs/4.16.6#sortedIndexBy
      */

      let indexOfTargetCreativeDimensions = sortedIndexBy(
        sortedPotentialCreativeDimensions,
        [slotWidth],
        function(arr) {
          return arr[0];
        }) - 1;

      return sortedPotentialCreativeDimensions[indexOfTargetCreativeDimensions];
    }
  }

  configurePubAds() {

    googletag.cmd.push(function() {
      googletag.pubads().enableSingleRequest();
    });
  }

  defineAdSlots() {
    each(this.options.$adSlots, bind(this.defineAdSlot, this));
  }

  defineAdSlot(adSlot) {
    let $adSlot                     = $(adSlot);
    let slotWidth                   = $adSlot.width();
    let potentialCreativeDimensions = $adSlot.data('creative-dimensions');
    let targetCreativeDimensions    = this.findCreativeDimensions(slotWidth, potentialCreativeDimensions);

    if (targetCreativeDimensions) {
      let id                = $adSlot.data('slot-id');
      let networkCode       = $adSlot.data('network-code');
      let targetedAdUnit    = $adSlot.data('targeted-ad-unit');
      let targeting         = $adSlot.data('targeting');

      googletag.cmd.push(function() {
        let definedSlot = googletag.defineSlot(
          `/${networkCode}/${targetedAdUnit}`,
          [targetCreativeDimensions],
          id
        ).addService(googletag.pubads());

        each(targeting, function(value, key) {
          definedSlot.setTargeting(key, value);
        });
      });
    }
  }

  enableServices() {
    googletag.cmd.push(function() {
      googletag.enableServices();
    });
  }

  displayAds() {
    each(this.options.$adSlots, bind(this.displayAd, this));
  }

  displayAd(adSlot) {
    googletag.cmd.push(function() {
      let id = $(adSlot).data('slot-id');

      googletag.display(id);
    });
  }

  attach() {
    googletag.cmd.push(function() {
      googletag.pubads()
               .addEventListener('slotRenderEnded', function(event) {
                 let id           = event.slot.getSlotElementId();
                 let hasCreative  = !event.isEmpty;

                 if (hasCreative) {
                   $(`[data-slot-id="${id}"]`).addClass('ad-slot--rendered');
                 }
               });
    });
  }

  init(options = {}) {
    if (!window.googletag || !MediaQuery.isAtLeast('large')) {
      return;
    }

    this.options = merge({}, this.constructor.defaults, options);

    this.setDomDependentOptions();

    $(window).on('load', () => {
      this.configurePubAds();
      this.attach();
      this.defineAdSlots();
      this.enableServices();
      this.displayAds();
    });
  }
}

export default DfpComponent;
