import extend from 'lodash/extend';
import ApplicationController from 'core/controllers/application';
import JumplinksComponent from 'components/jumplinks';
import ContentRevealerComponent from 'components/content_revealer';
import SearchInputComponent from 'components/search_input';
import DfpComponent from 'components/dfp';

class RegionsShowController extends ApplicationController {
  constructor(options = {}) {
    super(options);

    extend(this.components, {
      jumplinks: new JumplinksComponent(),
      contentRevealer: new ContentRevealerComponent(),
      dfp: new DfpComponent(),
      searchInput: new SearchInputComponent()
    });
  }

  init() {
    super.init();

    this.components.jumplinks.init();
    this.components.contentRevealer.init();
    this.components.searchInput.init({ selector: '.regions-show__sidebar__search-input' });
    this.components.dfp.init();
  }
}

export default RegionsShowController;
