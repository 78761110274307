import $ from 'jquery';
import bind from 'lodash/bind';
import merge from 'lodash/merge';
import extend from 'lodash/extend';
import moment from 'moment';
import i18n from 'utils/i18n';
import DatePickerComponent from 'components/date_picker';

class SearchInputComponent {
  constructor() {
    this.components = {
      datePicker: new DatePickerComponent()
    };
  }

  static get defaults() {
    return Object.freeze({
      selector: '.search-input',
      dateFormatParam: i18n.l('date.param'),
      dateFormatText: i18n.l('date.readable'),
      classes: {
        datePicker: 'search-input__date-picker',
        datePickerText: 'search-input__date-picker-text',
        datePickerAnchor: 'search-input__date-picker-anchor',
        dateField: 'search-input__date',
        regionSelect: 'search-input__regions',
        form: 'search-input__form'
      },
      actions: {}
    });
  }

  setDate(date) {
    let dateParam = moment(date).format(this.options.dateFormatParam);
    let dateText  = moment(date).format(this.options.dateFormatText);

    this.options.$dateField.val(dateParam);
    this.options.$datePickerText.text(dateText);
  }

  setDomDependentOptions() {
    this.options.$scope          = $(this.options.selector);
    this.options.$datePicker     = this.options.$scope.find(`.${this.options.classes.datePicker}`);
    this.options.$datePickerText = this.options.$scope.find(`.${this.options.classes.datePickerText}`);
    this.options.$dateField      = this.options.$scope.find(`.${this.options.classes.dateField}`);
    this.options.$form           = this.options.$scope.find(`.${this.options.classes.form}`);
  }

  initDatePicker() {
    let defaultDate = this.options.$dateField.val() || '';
    let options     =  {
      selector: `${this.options.selector} .${this.options.classes.datePicker}`,
      widget: {
        minDate: moment().toDate(),
        container: $(`${this.options.selector} .${this.options.classes.datePickerAnchor}`)[0]
      }
    };

    if (defaultDate.length) {
      extend(options.widget, {
        defaultDate: moment(defaultDate).toDate(),
        setDefaultDate: true
      });
    }

    this.components.datePicker.init(options);
  }

  updateSearchAction(event) {
    let $form    = this.options.$form;
    let endpoint = $form.data('endpoint');
    let value    = event.currentTarget.value;

    $form.attr('action', `${endpoint}${value}`);
  }

  attach() {
    this.options.$scope.on(
      'change.search-input:region-change',
      `.${this.options.classes.regionSelect}`,
      bind(this.updateSearchAction, this)
    );
  }

  init(options = {}) {
    this.options = merge({}, this.constructor.defaults, options);

    this.setDomDependentOptions();
    this.attach();
    this.initDatePicker();
  }
}

export default SearchInputComponent;
