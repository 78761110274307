import locales from 'locales';
import Logger from 'utils/logger';
import _get from 'lodash/get';
import template from 'lodash/template';
import isString from 'lodash/isString';
import includes from 'lodash/includes';
import $ from 'jquery';
import moment from 'moment';

/*
MOMENT LOCALES
==============
Note that there is no zh locale in moment.js.

This is okay so long as we do not allow users to select zh as a locale, only
ever zh-CN.

See: https://github.com/moment/moment/issues/3099#issuecomment-251071233
*/
import 'moment/locale/fr';
import 'moment/locale/fr-ca';
import 'moment/locale/de';
import 'moment/locale/es';
import 'moment/locale/it';
import 'moment/locale/ja';
import 'moment/locale/zh-cn';

const DEFAULT_LOCALE = 'en';
const SUPPORTED_LOCALES = [
  DEFAULT_LOCALE,
  'en-US',
  'fr',
  'fr-CA',
  'fr-FR',
  'de',
  'de-x-du',
  'es',
  'it',
  'ja',
  'zh',
  'zh-CN'
];

let I18n = {
  defaultLocale: DEFAULT_LOCALE,

  _isSupportedLocale(requestedLocale) {
    return includes(SUPPORTED_LOCALES, requestedLocale);
  },

  _getRequestedLocale() {
    return $('body').data('locale');
  },

  _setLocale() {
    let requestedLocale   = this._getRequestedLocale();
    let isSupportedLocale = this._isSupportedLocale(requestedLocale);

    this.locale = isSupportedLocale ? requestedLocale : this.defaultLocale;
    moment.locale(this.locale);

    if (!isSupportedLocale) {
      Logger.warn(`UNSUPPORTED LOCALE: "${requestedLocale}" -- Falling back to "${this.defaultLocale}"!`);
    }
  },

  _getTranslation(path) {
    const requestedTranslation = _get(locales, `${this.locale}.${path}`);
    const fallbackTranslation = this.locale.indexOf('-') ? _get(locales, `${this.locale.split('-')[0]}.${path}`) : void(0);

    if (requestedTranslation) {
      return requestedTranslation;
    } else if (fallbackTranslation) {
      return fallbackTranslation;
    } else {
      let defaultTranslation = _get(locales, `${this.defaultLocale}.${path}`);

      if (defaultTranslation) {
        Logger.warn(`TRANSLATION MISSING: { path: "${path}", locale: "${this.locale}" } -- Loading default in "${this.defaultLocale}"!`);
        return defaultTranslation;
      } else {
        Logger.error(`TRANSLATION MISSING: { path: "${path}", locale: "${this.locale}" } -- No default found!`);
      }
    }
  },

  _processTranslation(translation, options) {
    if (isString(translation)) {
      let compiledTemplate     = template(translation);
      let processedTranslation = compiledTemplate(options);

      return processedTranslation;
    } else {
      return translation;
    }
  },

  t(path, options = {}) {
    if (!this.locale) { this._setLocale(); }

    let unprocessedTranslation = this._getTranslation(path);
    let processedTranslation   = this._processTranslation(unprocessedTranslation, options);

    return processedTranslation;
  },

  l() {
    return this.t.apply(this, arguments);
  }
};

export default I18n;
