import extend from 'lodash/extend';
import ApplicationController from 'purchase_path/controllers/application';
import GiftCardPaymentFormComponent from 'components/gc_payment_form';
import GiftCardAmountSelector from 'components/gc_amount_selector';

class GiftcardsNewController extends ApplicationController {
  constructor(options = {}) {
    super(options);

    extend(this.components, {
      giftCardPaymentFormComponent: new GiftCardPaymentFormComponent(),
      giftCardAmountSelector: new GiftCardAmountSelector(),
    });
  }

  init() {
    super.init();

    const {
      giftCardPaymentFormComponent,
      giftCardAmountSelector,
    } = this.components;

    giftCardPaymentFormComponent.init();
    giftCardAmountSelector.init();
  }
}

export default GiftcardsNewController;
