import $ from 'jquery';
import bind from 'lodash/bind';
import merge from 'lodash/merge';

class AmenitiesComponent {
  constructor() {}

  static get defaults() {
    return Object.freeze({
      selector: '.amenities',
      classes: {
        isVisible: 'amenities--visible',
        visibilityToggle: 'amenities__toggle',
        toggleText: 'amenities__toggle-text'
      },
      text: {
        visible: 'Hide',
        hidden: 'View'
      }
    });
  }

  toggleInactiveAmenityVisibility(event) {
    event.preventDefault();

    let $toggle                     = $(event.currentTarget);
    let $amenities                  = $toggle.parents(this.options.selector);
    let $toggleText                 = $amenities.find(`.${this.options.classes.toggleText}`);
    let inactiveAmenitiesAreVisible = $amenities.hasClass(this.options.classes.isVisible);

    if (inactiveAmenitiesAreVisible) {
      $toggleText.text(this.options.text.hidden);
    } else {
      $toggleText.text(this.options.text.visible);
    }

    $amenities.toggleClass(this.options.classes.isVisible);
  }

  attach() {
    let $scope = $(this.options.selector);

    $scope.on(
      'click.amenities:toggle-click',
      `.${this.options.classes.visibilityToggle}`,
      bind(this.toggleInactiveAmenityVisibility, this)
    );
  }

  init(options) {
    this.options = merge({}, this.constructor.defaults, options);

    this.attach();
  }
}

export default AmenitiesComponent;
