import $ from 'jquery';

const APPLICATION_CHROME_OFFSET_TOP = parseInt($('body').css('padding-top'), 10) + 16;

const CLOUDINARY = {
  uploadPresets: {"guest_photos":"production_liftopia_guest_photos"},
  cloudName: "liftopia",
  stylesheetUrl: `https://assets2.liftopia.com/3bd272468258aaf4a55554e1600ac7e04124d9ab/assets/client/components/photo_uploader-6cb718ef0f005211f2f2338e146def33c1a86f6bd6aad739378516325f23f1fb.css`
};


const CSS = {
  layout: {
    globalPadding: '16px'
  },
  typography: {
    baseFontSize: '16px',
    baseFontFamily: 'Roboto, Helvetica, Arial, sans-serif'
  },
  colors: {
    black: '#000',
    white: '#fff',
    orange10: '#EE7E43',
    orange9: '#F29869',
    orange8: '#F4AB85',
    orange7: '#F7BEA1',
    yellow10: '#DFDA22',
    yellow9: '#E2DE38',
    yellow8: '#E5E14E',
    yellow7: '#E9E564',
    yellow6: '#ECE97A',
    yellow5: '#EFEC90',
    yellow4: '#F2F0A7',
    yellow3: '#F5F4BC',
    yellow2: '#F9F8D3',
    yellow1: '#FCFBE8',
    green10: '#22AF60',
    green9: '#38B770',
    green8: '#4EBF80',
    green7: '#64C78F',
    green6: '#7ACFA0',
    green5: '#90D7AF',
    green4: '#A7DFBF',
    green3: '#BCE7CF',
    green2: '#D3EFDF',
    green1: '#E8F7EF',
    blue10: '#0085ca',
    blue9: '#2492cd',
    blue8: '#3c9ed3',
    blue7: '#55aad8',
    blue6: '#6DB6DE',
    blue5: '#85C2E3',
    blue4: '#9DCEE9',
    blue3: '#B5DAEE',
    blue2: '#CEE7F4',
    blue1: '#E6F3F9',
    'light-blue1': '#2196F3',
    charcoal10: '#1B2731',
    charcoal9: '#333D46',
    charcoal8: '#49525A',
    charcoal7: '#60686F',
    charcoal6: '#767D83',
    charcoal5: '#8D9298',
    charcoal4: '#A4A8AD',
    charcoal3: '#BABEC1',
    charcoal2: '#D1D4D6',
    charcoal1: '#E8E9EA',
    slate10: '#34454E',
    slate9: '#495860',
    slate8: '#5D6B72',
    slate7: '#717D84',
    slate6: '#859095',
    slate5: '#99A2A6',
    slate4: '#AEB5B8',
    slate3: '#C2C7CA',
    slate2: '#D6DADC',
    slate1: '#EAECED',
    gray10: '#A4AAAD',
    gray9: '#ADB2B5',
    gray8: '#B6BBBE',
    gray7: '#C0C3C6',
    gray6: '#C8CCCE',
    gray5: '#D1D4D6',
    gray4: '#DBDDDE',
    gray3: '#E4E5E6',
    gray2: '#EDEEEF',
    gray1: '#F6F6F7',
    red10: '#8E1E21',
    red9: '#9A3537',
    red8: '#A54B4D',
    red7: '#B16264',
    red6: '#BC787A',
    red5: '#C78E8F',
    red4: '#D2A5A6',
    red3: '#DDBBBC',
    red2: '#E9D2D3',
    red1: '#F4E8E8',
    frostgray10: '#608090',
    frostgray9: '#708D9B',
    frostgray8: '#809AA6',
    frostgray7: '#8fa6b1',
    frostgray6: '#A0B3BC',
    frostgray5: '#AFBFC7',
    frostgray4: '#BFCCD3',
    frostgray3: '#CFD9DE',
    frostgray2: '#DFE6E9',
    frostgray1: '#EFF2F4',
    teal10: '#03514a',
    teal9: '#1d625c',
    teal8: '#36736e',
    teal7: '#4f8580',
    teal6: '#689692',
    teal5: '#81a7a4',
    teal4: '#9ab9b6',
    teal3: '#b3cac8',
    teal2: '#cddcbd',
    teal1: '#e5eded',
    apreswine10: '#2C1823',
    apreswine9: '#423039',
    apreswine8: '#56464F',
    apreswine7: '#6C5E65',
    apreswine6: '#80757B',
    apreswine5: '#958B90',
    apreswine4: '#ABA3A7',
    apreswine3: '#BFB9BC',
    apreswine2: '#D5D1D3',
    apreswine1: '#E9E7E9',
    facebookblue: '#3b5998',
    snow: '#f5f5f5',
    glacier: '#eff2f4',
    cloud: '#d0d6d9',
    storm: '#a4aaad',
    gravel: '#7b878e',
    river: '#608090',
    gunmetal: '#34454e',
    slate: '#1b2731',
    bluesky: '#77a2d6',
    lagoon: '#36AEEB',
    liftopiablue: '#0085ca',
    royalblue: '#1976d2',
    evergreen: '#03514a',
    meadow: '#22af60',
    hazard: '#dfda22',
    liftopiaorange: '#ee7e43',
    tangerine: '#ed6033',
    brickred: '#8e1e21'
  }
};

export {
  APPLICATION_CHROME_OFFSET_TOP,
  CSS,
  CLOUDINARY
};
