/* global google */
import $ from 'jquery';
import bind from 'lodash/bind';
import merge from 'lodash/merge';
import each from 'lodash/each';

class GoogleMapComponent {
  constructor() {}

  static get defaults() {
    return Object.freeze({
      selector: '.google-map',
      classes: {
        loading: 'google-map--loading',
        content: 'google-map__content'
      },
      actions: {}
    });
  }

  setDomDependentOptions() {
    this.options.$scope      = $(this.options.selector);
    this.options.markerIcon  = this.options.$scope.data('marker-icon');
    this.options.markersData = this.options.$scope.data('markers-data');
  }

  setPreloadState() {
    this.options.$scope.addClass(this.options.classes.loading);
  }

  setPostloadState() {
    let { markersData } = this.options;

    this.setUpMap({
      lat: markersData.latitude,
      lng: markersData.longitude
    }, markersData.resorts);

    this.options.$scope.removeClass(this.options.classes.loading);
  }

  render() {
    this.setPreloadState();
    this.setPostloadState();
  }

  setUpMap(centerCoords, resorts){
    this.map = this.initMap(centerCoords);

    google.maps.event.addListener(this.map, 'click', bind(this.closeActiveToolTip, this));

    each(resorts, bind(this.setUpMarker, this));
  }

  setUpMarker(resort){
    var marker = new google.maps.Marker({
      position: {
        lat: parseFloat(resort.latitude),
        lng: parseFloat(resort.longitude)
      },
      map: this.map,
      title: resort.name,
      icon: this.createImage(this.options.markerIcon)
    });

    var toolTip = this.createToolTip(resort.info_window);
    google.maps.event.addListener(marker, 'click', bind(function() {
      this.openToolTip(toolTip, marker);
    }, this));

    google.maps.event.addListener(marker, 'mouseover', bind(function() {
      this.openToolTip(toolTip, marker);
    }, this));
  }

  initMap(coords) {
    return new google.maps.Map(document.getElementById('map'), {
      center: coords,
      zoom: 7,
      scrollwheel: false
    });
  }

  closeActiveToolTip() {
    if (this.activeToolTip && this.activeMarker) {
      this.activeToolTip.close(this.map, this.activeMarker);
    }
  }

  createImage(url) {
    var image = {
      url: url,
      size: new google.maps.Size(32, 32),
      origin: new google.maps.Point(0,0),
      anchor: new google.maps.Point(0, 32)
    };

    return image;
  }

  createToolTip(text){
    return new google.maps.InfoWindow({ content: text });
  }

  openToolTip(toolTip, marker){
    this.closeActiveToolTip();

    this.activeToolTip = toolTip;
    this.activeMarker = marker;
    this.activeToolTip.open(this.map, this.activeMarker);
  }

  init(options) {
    this.options = merge({}, this.constructor.defaults, options);

    this.setDomDependentOptions();
    this.render();
  }
}

export default GoogleMapComponent;
