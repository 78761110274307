import $ from 'jquery';
import bind from 'lodash/bind';
import merge from 'lodash/merge';
import extend from 'lodash/extend';
import Pikaday from 'pikaday';
import moment from 'moment';
import i18n from 'utils/i18n';

class DatePickerComponent {
  constructor() {}

  static get defaults() {
    return Object.freeze({
      selector: '.date-picker',
      dateFormatParam: i18n.l('date.param'),
      dateFormatText: i18n.l('date.readable'),
      widget: i18n.l('components.date_picker'),
      classes: {
        input: 'input',
        text: 'date-picker__text',
        disabled: 'date-picker--disabled',
      },
      actions: {
        onSelect: function() {},
        onOpen: function() {},
        onClose: function() {},
        onDraw: function() {}
      }
    });
  }

  _handleClose() { this.options.actions.onClose.call(this, arguments); }
  _handleOpen() { this.options.actions.onOpen.call(this, arguments); }
  _handleDraw() { this.options.actions.onDraw.call(this, arguments); }
  _handleSelect(date) {
    let dateParam = moment(date).format(this.options.dateFormatParam);
    let dateText  = moment(date).format(this.options.dateFormatText);

    this.options.$input.val(dateParam).change();
    this.options.$text.text(dateText);
    this.options.actions.onSelect(date);
  }

  setWidgetOptions() {
    this.options.widget.field = this.options.$scope[0];

    extend(this.options.widget, {
      onSelect: bind(this._handleSelect, this),
      onOpen: bind(this._handleOpen, this),
      onClose: bind(this._handleClose, this),
      onDraw: bind(this._handleDraw, this),
    });
  }

  preventDefaultClick(event) {
    event.preventDefault();
  }

  _isDisabled() {
    return this.options.$scope.hasClass(this.options.classes.disabled);
  }

  initWidget() {
    if (this.$widget) { this.$widget.destroy(); }
    if (this._isDisabled()) { return; }

    let initialValue = this.options.$scope.find('input').val();
    if (initialValue) {
      extend(this.options.widget, {
        defaultDate: moment(initialValue).toDate(),
        setDefaultDate: true,
      });
      this.options.$text.text(moment(initialValue).format(this.options.dateFormatText));
    }

    this.$widget = new Pikaday(this.options.widget);

    // prevent Pikaday from taking over the keyboard
    document.removeEventListener('keydown', this.$widget._onKeyChange);
  }

  setDomDependentOptions() {
    this.options.$scope = $(this.options.selector);
    this.options.$input = this.options.$scope.find(this.options.classes.input);
    this.options.$text  = this.options.$scope.find(`.${this.options.classes.text}`);
  }

  attach() {
    this.options.$scope.on(
      'click.date-picker:click',
      bind(this.preventDefaultClick, this)
    );
  }

  init(options = {}) {
    this.options = merge({}, this.constructor.defaults, options);

    this.setDomDependentOptions();
    this.setWidgetOptions();
    this.attach();
    this.initWidget();
  }
}

export default DatePickerComponent;
