import $ from 'jquery';
import bind from 'lodash/bind';
import merge from 'lodash/merge';

class ContentRevealerComponent {
  constructor() {}

  static get defaults() {
    return Object.freeze({
      selector: '.content-revealer',
      classes: {
        visibilityToggle: 'content-revealer__visibility-toggle',
        isVisible: 'content-revealer--visible'
      },
      text: {
        visible: 'Read less',
        hidden: 'Read more'
      }
    });
  }

  init(options) {
    this.options = merge({}, this.constructor.defaults, options);

    this.attach();
  }

  attach() {
    let $scope = $(this.options.selector);

    $scope.on(
      'click.content-revealer:toggle-visibility',
      `.${this.options.classes.visibilityToggle}`,
      bind(this.toggleVisibility, this)
    );
  }

  toggleVisibility(event) {
    event.preventDefault();

    let $toggle          = $(event.currentTarget);
    let $contentRevealer = $toggle.parents(this.options.selector);
    let isVisible        = $contentRevealer.hasClass(this.options.classes.isVisible);

    if (isVisible) {
      $toggle.text(this.options.text.hidden);
    } else {
      $toggle.text(this.options.text.visible);
    }

    $contentRevealer.toggleClass(this.options.classes.isVisible);
  }
}

export default ContentRevealerComponent;
