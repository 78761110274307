import $ from 'jquery';
import bind from 'lodash/bind';
import merge from 'lodash/merge';

class ButtonComponent {
  constructor() {
    this._autoBindPublicMethods();
  }

  static get defaults() {
    return Object.freeze({
      selector: '.button',
      classes: {
        loading: 'button--loading',
        success: 'button--success',
        error: 'button--error'
      },
      actions: {}
    });
  }

  removeStatefulClasses() {
    this._removeStatefulClasses();
  }

  setLoadingState() {
    this._removeStatefulClasses();

    this.options.$scope.addClass(this.options.classes.loading);
  }

  setSuccessState() {
    this._removeStatefulClasses();

    this.options.$scope.addClass(this.options.classes.success);
  }

  setErrorState() {
    this._removeStatefulClasses();

    this.options.$scope.addClass(this.options.classes.error);
  }

  enable() {
    this.options.$scope.attr('disabled', false);
  }

  disable() {
    this.options.$scope.attr('disabled', true);
  }

  isDisabled() {
    return this.options.$scope.attr('disabled') === 'disabled';
  }

  _autoBindPublicMethods() {
    bind(this.setSuccessState, this);
    bind(this.setErrorState, this);
    bind(this.setLoadingState, this);
    bind(this.enable, this);
    bind(this.disable, this);
  }

  _removeStatefulClasses() {
    let { $scope, classes } = this.options;

    $scope.removeClass(`${classes.loading} ${classes.success} ${classes.error}`);
  }

  _setDomDependentOptions() {
    this.options.$scope = $(this.options.selector);
  }

  _attach() {
    if (this.options.actions.onClick) {
      this.options.$scope.on('click.button:click', this.options.actions.onClick);
    }
  }

  init(options) {
    this.options = merge({}, this.constructor.defaults, options);

    this._setDomDependentOptions();
    this._attach();
  }
}

export default ButtonComponent;
