import $ from 'jquery';
import extend from 'lodash/extend';
import each from 'lodash/each';
import reduce from 'lodash/reduce';
import find from 'lodash/find';
import URI from 'urijs';

let MediaQueryUtil = {
  options: {
    initialized: false,
    selector: '.media-query',
    queries: [],
    queryMap: {
      'default': 'only screen',
      landscape: 'only screen and (orientation: landscape)',
      portrait: 'only screen and (orientation: portrait)',
      retina: 'only screen and (-webkit-min-device-pixel-ratio: 2),' +
              'only screen and (min--moz-device-pixel-ratio: 2),' +
              'only screen and (-o-min-device-pixel-ratio: 2/1),' +
              'only screen and (min-device-pixel-ratio: 2),' +
              'only screen and (min-resolution: 192dpi),' +
              'only screen and (min-resolution: 2dppx)'
    }
  },

  getBreakpoint() {
    if (!this.options.initialized) {
      this._init();
    }

    return reduce(this.options.queries, (breakpoint, query) => {
      let queryMatches = window.matchMedia(query.value).matches;

      if (queryMatches) {
        breakpoint = query.name;
      }

      return breakpoint;
    });
  },

  isAtLeast(targetBreakpoint) {
    if (!this.options.initialized) {
      this._init();
    }

    let targetQuery = find(this.options.queries, (query) => {
      return query.name === targetBreakpoint;
    });

    return targetQuery
      ? window.matchMedia(targetQuery.value).matches
      : false;
  },

  _polyFillMatchMedia() {
    window.matchMedia || (window.matchMedia = function() {
      'use strict';

      let styleMedia = (window.styleMedia || window.media);

      if (!styleMedia) {
        let style  = document.createElement('style');
        let script = document.getElementsByTagName('script')[0];
        let info   = null;

        style.type  = 'text/css';
        style.id    = 'matchmediajs-test';

        script && script.parentNode && script.parentNode.insertBefore(style, script);

        info = ('getComputedStyle' in window) && window.getComputedStyle(style, null) || style.currentStyle;

        styleMedia = {
          matchMedium(media) {
            let text = `@media ${media}{ #matchmediajs-test { width: 1px; } }`;

            if (style.styleSheet) {
              style.styleSheet.cssText = text;
            } else {
              style.textContent = text;
            }

            return info.width === '1px';
          }
        };
      }

      return function(media) {
        return {
          matches: styleMedia.matchMedium(media || 'all'),
          media: media || 'all'
        };
      };
    }());
  },

  _setQueries() {
    let { queryMap, queries, $scope } = this.options;
    let rawMediaQueries               = $scope.css('font-family');
    let cleanedMediaQueries           = rawMediaQueries ? rawMediaQueries.replace(/['"]+/g, '') : '';
    let parsedMediaQueries            = new URI(cleanedMediaQueries).query(true);

    extend(queryMap, parsedMediaQueries);

    each(queryMap, (value, key) => {
      queries.push({
        name: key,
        value: `only screen and (min-width: ${value})`
      });
    });
  },

  _setDomDependentOptions() {
    this.options.$scope = $(this.options.selector);
  },

  _init() {
    this._setDomDependentOptions();
    this._polyFillMatchMedia();
    this._setQueries();

    this.options.initialized = true;
  }
};

export default MediaQueryUtil;
