import $ from 'jquery';
import merge from 'lodash/merge';
import extend from 'lodash/extend';
import moment from 'moment';
import i18n from 'utils/i18n';
import DatePickerComponent from 'components/date_picker';

class CloudstoreSearchInputComponent {
  constructor() {
    this.components = {
      datePicker: new DatePickerComponent()
    };
  }

  static get defaults() {
    return Object.freeze({
      selector: '.cloudstore-search-input',
      dateFormatParam: i18n.l('date.param'),
      classes: {
        spinner: 'cloudstore-search-input__loading_spinner',
        submit: 'search-input__submit',
        datePicker: 'cloudstore-search-input__date-picker',
        datePickerAnchor: 'cloudstore-search-input__date-picker-anchor',
        dateField: 'cloudstore-search-input__date',
        storeDate: 'cloudstore-search-input__partner_date',
        form: 'cloudstore-search-input__form',
        quickSelectOptions: 'cloudstore-search-input__quick-select__radio',
        categoryFilter: 'cloudstore-search-input__category-filter',
      },
      actions: {}
    });
  }

  _todayMoment()    { return moment(this.options.$storeDate.val()); }
  _tomorrowMoment() { return this._todayMoment().add(1, 'days'); }
  _saturdayMoment() { return this._todayMoment().day(6); }
  _sundayMoment()   { return this._todayMoment().day(0); }
  _isSaturday()     { return this._todayMoment().dayOfYear() == this._saturdayMoment().dayOfYear(); }
  _isSunday()       { return this._todayMoment().dayOfYear() == this._sundayMoment().dayOfYear(); }
  _weekendMoment()  { return this._isSunday() ? this._sundayMoment() : this._saturdayMoment(); }

  _uncheckAllRadios() {
    this.options.quickSelectOptions.forEach( input => input.checked = false );
  }

  _handleRadioSelect(radioNode) {
    const { $widget } = this.components.datePicker;

    switch (radioNode.value) {
      case 'today':
        $widget.setMoment( this._todayMoment() );
        break;
      case 'tomorrow':
        $widget.setMoment( this._tomorrowMoment() );
        break;
      case 'weekend':
        $widget.setMoment( this._weekendMoment() );
        this._uncheckAllRadios();
        this.options.quickSelectOptions[2].checked = true;
        break;
    }
  }

  _handleCalendarSelect() {
    this._uncheckAllRadios();
    const widgetDay = this.components.datePicker.$widget.getMoment().dayOfYear();

    switch (widgetDay) {
      case this._todayMoment().dayOfYear():
        this.options.quickSelectOptions[0].checked = true;
        break;
      case this._tomorrowMoment().dayOfYear():
        this.options.quickSelectOptions[1].checked = true;
        break;
      case this._weekendMoment().dayOfYear():
        this.options.quickSelectOptions[2].checked = true;
        break;
    }
  }

  _handleCategoryFilterInput() {
    const { $spinner, $form } = this.options;

    $spinner.removeClass('visually-hidden');
    $form.submit();
  }

  _attach() {
    const { quickSelectOptions, $categories } = this.options;

    quickSelectOptions.forEach( input => {
      input.addEventListener('change', (e) => this._handleRadioSelect(e.target));
    });

    $categories.on('change', this._handleCategoryFilterInput.bind(this));
  }

  setDomDependentOptions() {
    const { classes } = this.options;

    this.options.$scope      = $(this.options.selector);
    this.options.$datePicker = this.options.$scope.find(`.${classes.datePicker}`);
    this.options.$dateField  = this.options.$scope.find(`.${classes.dateField}`);
    this.options.$storeDate  = this.options.$scope.find(`.${classes.storeDate}`);
    this.options.$form       = this.options.$scope.find(`.${classes.form}`);
    this.options.$categories = this.options.$scope.find(`.${classes.categoryFilter}`);
    this.options.$spinner    = this.options.$categories.find(`.${classes.spinner}`);
    this.options.quickSelectOptions = Array.from(document.querySelectorAll(`.${classes.quickSelectOptions}`));
  }

  _hideToday() {
    const dataHideToday = document.querySelector('[data-hide-today]');
    
    if (dataHideToday) {
      return dataHideToday.dataset.hideToday.toLowerCase() === 'true';
    }
    
    return false;
  }

  _handleMinDate() {
    if (this._hideToday()) {
      return this._tomorrowMoment().toDate();
    } 
    
    return this._todayMoment().toDate();
  }

  initDatePicker() {
    let defaultDate = this.options.$dateField.val() || '';
    let options     =  {
      selector: `${this.options.selector} .${this.options.classes.datePicker}`,
      widget: {
        minDate: this._handleMinDate(),
        container: $(`${this.options.selector} .${this.options.classes.datePickerAnchor}`)[0]
      },
      actions: {
        onSelect: this._handleCalendarSelect.bind(this),
      },
    };

    if (defaultDate.length) {
      extend(options.widget, {
        defaultDate: moment(defaultDate).toDate(),
        setDefaultDate: true
      });
    }

    this.components.datePicker.init(options);
  }

  init(options = {}) {
    this.options = merge({}, this.constructor.defaults, options);

    this.setDomDependentOptions();
    this.initDatePicker();
    this._attach();
  }
}

export default CloudstoreSearchInputComponent;
