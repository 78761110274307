import $ from 'jquery';
import URI from 'urijs';

const iosAppUrl = 'liftopia://web2app/';

let IosAppUtil = {
  isIosApp() {
    return $('body').hasClass('origin-ios-app');
  },

  sendAppMessage(message, params = {}) {
    let url = new URI(iosAppUrl + message).addQuery(params).toString();
    let $iframe = $('<iframe />', { src: url });
    $('body').append($iframe);
    $iframe.remove();
  },

  setPageTitle(title) {
    if(this.isIosApp()) {
      this.sendAppMessage('setPageTitleAndProgress', { n: title });
    }
  },

  userSignedIn(userName) {
    if(this.isIosApp()) {
      this.sendAppMessage('userSignedIn', { u: userName });
    }
  },

  hideBackButton() {
    if(this.isIosApp()) {
      this.sendAppMessage('hideBackButton');
    }
  },

  showBackButton() {
    if(this.isIosApp()) {
      this.sendAppMessage('showBackButton');
    }
  },

  hideCloseButton() {
    if(this.isIosApp()) {
      this.sendAppMessage('hideCloseButton');
    }
  },

  showCloseButton() {
    if(this.isIosApp()) {
      this.sendAppMessage('showCloseButton');
    }
  },

  initPage() {
    // default behavior is to show the back button and not the close button. Body classes can
    // be added to a view to override these defaults.
    if($('body').hasClass('webview-with-close-button')) {
      this.showCloseButton();
    } else {
      this.hideCloseButton();
    }
    if($('body').hasClass('webview-without-back-button')) {
      this.hideBackButton();
    } else {
      this.showBackButton();
    }
  },
};

export default IosAppUtil;
