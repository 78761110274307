import $ from 'jquery';
import merge from 'lodash/merge';
import bind from 'lodash/bind';
import each from 'lodash/each';
import reduce from 'lodash/reduce';
import ModalComponent from 'components/modal';
import ButtonComponent from 'components/button';
import i18n from 'utils/i18n';

const UNNEEDED_ERROR_TYPE = 'store_id';

class ForgotPasswordModalComponent extends ModalComponent {
  constructor() {
    super();

    this.components = {
      submitButton: new ButtonComponent()
    };
  }

  static get defaults() {
    let defaults = merge({}, super.defaults, {
      trigger: '.forgot-password-modal__trigger',
      selector: '.forgot-password-modal',
      errorTimeout: 2500,
      successTimeout: 1250,
      submitTimeout: 1000,
      classes: {
        form: 'forgot-password-modal__form',
        submitButton: 'forgot-password-modal__submit-button',
        focusedField: 'forgot-password-modal__focused-field',
        errors: 'forgot-password-modal__errors',
        errorsVisible: 'forgot-password-modal__errors--visible',
        success: 'forgot-password-modal__success',
        successVisible: 'forgot-password-modal__success--visible'
      }
    });

    return Object.freeze(defaults);
  }

  _setPreloadState() {
    this.components.submitButton.setLoadingState();
  }

  _reloadWindow() {
    window.location.reload();
  }

  _open(event) {
    super._open(event);

    this.options.$focusedField.focus();
  }

  _close() {
    super._close();

    this._clearSuccess();
    this._clearErrors();
    this.components.submitButton.removeStatefulClasses();
  }

  _setSuccess() {
    let {
      $success,
      classes
    } = this.options;
    let successMessage = i18n.t('components.forgot_password_modal.success_message');

    $success.addClass(classes.successVisible);
    $success.html(successMessage);
  }

  _clearSuccess() {
    let {
      $success,
      classes
    } = this.options;

    $success.removeClass(classes.successVisible);
    $success.empty();
  }

  _buildErrors(errors) {
    return reduce(errors, ($errorsList, errorMessages, errorType) => {
      if (errorType === UNNEEDED_ERROR_TYPE) {
        return $errorsList;
      }

      each(errorMessages, (errorMessage) => {
        let $errorsListItem  = $('<li />', { class: 'forgot-password-modal__errors-list-item' });

        $errorsListItem.html(errorMessage);
        $errorsList.append($errorsListItem);
      });

      return $errorsList;
    }, $('<ul />', { class: 'forgot-password__modal-errors-list' }));
  }

  _setErrors(errors) {
    let {
      $errors,
      classes
    } = this.options;
    let $errorsList = this._buildErrors(errors);

    $errors.addClass(classes.errorsVisible);
    $errors.html($errorsList);
  }

  _clearErrors() {
    let {
      $errors,
      classes
    } = this.options;

    $errors.removeClass(classes.errorsVisible);
    $errors.empty();
  }

  _onSuccess() {
    this.components.submitButton.setSuccessState();

    this._clearErrors();
    this._setSuccess();

    setTimeout(
      bind(this._reloadWindow, this),
      this.options.successTimeout
    );
  }

  _onError(xhr) {
    let { submitButton } = this.components;
    let errors           = JSON.parse(xhr.responseText).errors;

    this._setErrors(errors);
    submitButton.setErrorState();

    setTimeout(() => {
      submitButton.removeStatefulClasses();
    }, this.options.errorTimeout);
  }

  _onFormSubmit(event) {
    event.preventDefault();

    let $form  = $(event.currentTarget);
    let url    = $form.attr('action');
    let data   = $form.serialize();

    this._setPreloadState();

    setTimeout(() => {
      $.ajax({
        url,
        data,
        method: 'POST',
        dataType: 'json'
      }).then(
        bind(this._onSuccess, this),
        bind(this._onError, this)
      );
    }, this.options.submitTimeout);
  }

  _setDomDependentOptions() {
    super._setDomDependentOptions();

    let {
      $scope,
      classes
    } = this.options;

    this.options.$form         = $scope.find(`.${classes.form}`);
    this.options.$submitButton = $scope.find(`.${classes.submitButton}`);
    this.options.$focusedField = $scope.find(`.${classes.focusedField}`);
    this.options.$errors       = $scope.find(`.${classes.errors}`);
    this.options.$success      = $scope.find(`.${classes.success}`);
  }

  _attach() {
    super._attach();

    this.options.$form.on('submit.forgot-password-modal:form-submit', bind(this._onFormSubmit, this));
  }

  init(options = {}) {
    super.init(options);

    this.components.submitButton.init({
      selector: `.${this.options.classes.submitButton}`
    });
  }
}

export default ForgotPasswordModalComponent;
