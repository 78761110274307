import $ from 'jquery';
import merge from 'lodash/merge';
import bind from 'lodash/bind';
import { APPLICATION_CHROME_OFFSET_TOP } from 'utils/constants.js.erb';

class AlertNewSkinComponent {
  constructor() {}

  static get defaults() {
    return Object.freeze({
      selector: '.alert-new-skin',
      autoDismiss: false,
      autoDismissDuration: 10000,
      scrollToOnShow: false,
      scrollToDuration: 200,
      offsetBuffer: APPLICATION_CHROME_OFFSET_TOP,
      classes: {
        dismissTrigger: 'alert-new-skin--dismiss-trigger',
        dismissed: 'alert-new-skin--dismissed'
      },
      actions: {
        onDismiss() {},
        onHide() {},
        onShow() {}
      }
    });
  }

  _scrollTo() {
    // Get global option
    const { $scope, scrollToDuration, offsetBuffer } = this.options;

    // Calculate the component's offset from the top of the page
    const offset = $scope.offset().top - offsetBuffer;

    // Animate scrolling to the components location
    $('html, body').animate({ scrollTop: offset }, scrollToDuration);
  }

  _setDomDependentOptions() {
    // Get all component instances on the page
    this.options.$scope = $(this.options.selector);
  }

  _dismiss(event) {
    // Prevent event bubbling
    event.preventDefault();

    // Hide the alert
    this.hide();

    // Execute the dismiss event handler
    this.options.actions.onDismiss();
  }

  _attach() {
    // Attach the dismiss toggle event listener
    this.options.$scope.on(
      'click.alert-new-skin:dismiss',
      `.${this.options.classes.dismissTrigger}`,
      bind(this._dismiss, this)
    );
  }

  init(options = {}) {
    // Merge the passed in options with the defaults
    this.options = merge({}, this.constructor.defaults, options);

    // Get necessary info from the DOM
    this._setDomDependentOptions();

    // Attach all necessary event listeners
    this._attach();
  }

  show() {
    // Get global option
    let {
      $scope,
      classes,
      actions,
      autoDismiss,
      autoDismissDuration,
      scrollToOnShow
    } = this.options;

    // Remove the css class that hides the alert
    $scope.removeClass(classes.dismissed);

    // Execute the show event handler
    actions.onShow();

    // Scroll to the newly shown alert if allowed
    if (scrollToOnShow) {
      this._scrollTo();
    }

    // Auto dismiss the alert if allowed
    if (autoDismiss) {
      window.setTimeout(
        () => this.hide(),
        autoDismissDuration
      );
    }
  }

  hide() {
    // Get global option
    let { $scope, classes } = this.options;

    // Add the css class that hides the alert
    $scope.addClass(classes.dismissed);

    // Execute the hide event handler
    this.options.actions.onHide();
  }
}

export default AlertNewSkinComponent;
