import $ from 'jquery';
import extend from 'lodash/extend';
import merge from 'lodash/merge';
/*eslint-disable no-unused-vars*/
import LazySizes from 'lazysizes';
/*eslint-enable no-unused-vars*/

class LazyLoaderComponent {
  constructor() { }

  static get defaults() {
    return Object.freeze({
      widget: {
        lazyClass: 'lazy-loader',
        preloadClass: 'lazy-loader--preloading',
        loadingClass: 'lazy-loader--loading',
        loadedClass: 'lazy-loader--loaded'
      },
      actions: {}
    });
  }

  attach() {
    $(document).on('lazybeforeunveil', (event) => {
      let $image = $(event.target);
      let backgroundSource = $image.data('bg');

      if (backgroundSource) {
        $image.css('background-image', `url('${backgroundSource}')`);
      }
    });
  }

  initWidget() {
    extend(window.lazySizesConfig, this.options.widget);

    window.lazySizes.init();
  }

  init(options = {}) {
    this.options = merge({}, this.constructor.defaults, options);

    this.attach();
    this.initWidget();
  }
}

export default LazyLoaderComponent;
