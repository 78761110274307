import $ from 'jquery';
import merge from 'lodash/merge';
import AccordionComponent from 'components/accordion';

class RelatedProductsComponent {
  constructor() {
    this.components = {
      accordion: new AccordionComponent(),
    };
  }

  static get defaults() {
    return Object.freeze({
      selector: '.related-products',
      endpoint: '',
      daysCache: {},
      simulatedLoadTime: 400,
      start_date: undefined,
      classes: {
        results: 'related-products__results',
        resultsHidden: 'related-products__results--hidden',
        placeholder: 'related-products__loading-placeholder',
        placeholderHidden: 'related-products__loading-placeholder--hidden',
        spinner: 'related-products__spinner',
        spinnerHidden: 'related-products__spinner--hidden',
      },
      actions: {},
    });
  }

  _requestDate(date) {
    return $.ajax({
      url: this.options.endpoint,
      data: {
        start_date: date,
      }
    });
  }

  render(date) {
    this._setPreloadState();

    if (this.options.daysCache[date]) {
      this._renderCachedDay(date, this.options.simulatedLoadTime);
    } else {
      this._requestDate(date)
      .then((data) => this._updateCache(data))
      .then(() => this._renderCachedDay(date));
    }
  }

  _renderCachedDay(date, delay) {
    if (delay) {
      setTimeout(() => this._setPostloadState(this.options.daysCache[date]), delay);
    } else {
      this._setPostloadState(this.options.daysCache[date]);
    }
  }

  _updateCache(data) {
    const currentContent = this.options.results.innerHTML;
    this.options.results.innerHTML = data;
    this.options.daysCache[this.options.results.firstChild.dataset.date] = this.options.results.innerHTML;
    this.options.results.innerHTML = currentContent;

    return data;
  }

  _setPreloadState() {
    this._addStatefulClasses();
  }

  _setPostloadState(data) {
    this.options.results.innerHTML = data;
    this._removeStatefulClasses();
    this._initAccordion();
  }

  _addStatefulClasses() {
    this.options.spinner.classList.remove(this.options.classes.spinnerHidden);
    this.options.placeholder.classList.remove(this.options.classes.placeholderHidden);
    this.options.results.classList.add(this.options.classes.resultsHidden);
  }

  _removeStatefulClasses() {
    this.options.placeholder.classList.add(this.options.classes.placeholderHidden);
    this.options.results.classList.remove(this.options.classes.resultsHidden);
    this.options.spinner.classList.add(this.options.classes.spinnerHidden);
  }

  _initAccordion() {
    this.components.accordion.init({
      selector: `${this.options.selector}__accordion`,
      scrollToOnExpand: false,
    });
  }

  _setDomDependentOptions() {
    this.options.scope       = document.querySelector(this.options.selector);
    this.options.endpoint    = this.options.scope.dataset.endpoint || this.options.endpoint;
    this.options.results     = this.options.scope.querySelector(`.${this.options.classes.results}`);
    this.options.placeholder = this.options.scope.querySelector(`.${this.options.classes.placeholder}`);
    this.options.spinner     = this.options.scope.querySelector(`.${this.options.classes.spinner}`);
  }

  init(options = {}) {
    this.options = merge({}, this.constructor.defaults, options);

    this._setDomDependentOptions();
    this.render(this.options.start_date);
  }
}

export default RelatedProductsComponent;
