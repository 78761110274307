import URI from 'urijs';

let isDevelopment   = process.env.NODE_ENV === 'development';
let isDebug         = new URI().query(true)['__lt'] === 'true'; // ?__lt=true
let supportsLogging = (isDevelopment || isDebug) && window.console;

let LoggerUtil = {
  log() {
    if (!supportsLogging) { return; }

    console.log(...arguments);
  },

  warn() {
    if (!supportsLogging) { return; }

    console.warn(...arguments);
  },

  error() {
    if (!supportsLogging) { return; }

    console.error(...arguments);
  }
};

export default LoggerUtil;
