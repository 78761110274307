import extend from 'lodash/extend';
import Logger from 'utils/logger';
import CoreTopBarComponent from 'components/core_top_bar';
import CloudstoreTopBarComponent from 'components/cloudstore_top_bar';
import LazyLoaderComponent from 'components/lazy_loader';
import AuthFlowComponent from 'components/auth_flow';
import CloudstoreSearchInputComponent from 'components/cloudstore_search_input';
import LanguageSelectorComponent from 'components/language_selector';
import IosAppUtil from 'utils/ios_app';
import AndroidAppUtil from 'utils/android_app';
import 'vendor/utils.js';

class ApplicationController {
  constructor(options = {}) {
    extend(this, options);

    this.components = {
      coreTopBar: new CoreTopBarComponent(),
      cloudstoreTopBar: new CloudstoreTopBarComponent(),
      lazyLoader: new LazyLoaderComponent(),
      authFlow: new AuthFlowComponent(),
      cloudstoreSearchInput: new CloudstoreSearchInputComponent(),
      languageSelector: new LanguageSelectorComponent()
    };
  }

  _isCore() {
    return this.storeContext === 'core';
  }

  _isCloudstore() {
    return this.storeContext === 'cloudstore';
  }

  _storeContext() {
    return this.storeContext;
  }

  init() {
    let {
      coreTopBar,
      cloudstoreTopBar,
      lazyLoader,
      authFlow,
      cloudstoreSearchInput,
      languageSelector
    } = this.components;

    if (this._isCore()) {
      coreTopBar.init();
      // ios initPage needs to fire even on back button display of page from history, hence onpageshow
      window.onpageshow = function () { IosAppUtil.initPage(); };
      window.onpageshow = function () { AndroidAppUtil.initPage(); };
    }

    if (this._isCloudstore()) {
      cloudstoreTopBar.init();
      cloudstoreSearchInput.init();
      languageSelector.init();
    }

    lazyLoader.init();
    authFlow.init();

    Logger.log('DEBUG:', this);
  }
}

export default ApplicationController;
