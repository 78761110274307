import $ from 'jquery';
import merge from 'lodash/merge';
import bind from 'lodash/bind';
import each from 'lodash/each';
import every from 'lodash/every';
import map from 'lodash/map';
import reduce from 'lodash/reduce';
import { validate, validators } from 'utils/validators';
import { masking } from 'utils/input_masking';
import keyCodeUtil from 'utils/key_code';
import 'vendor/intlTelInput';
import 'vendor/utils';

class FormValidatorComponent {
  constructor(options = {}) {
    this.options = merge({}, this.constructor.defaults, options);

    this._autoBindPublicMethods();
    this.init();
  }

  static get defaults() {
    return Object.freeze({
      selector: '.form-validator',
      validatedInputMap: {},
      valid: false,
      debounceDelay: 650,
      classes: {
        inputGroup: 'form-validator__input-group',
        inputGroupProcessing: 'form-validator__input-group--processing',
        inputGroupValid: 'form-validator__input-group--valid',
        inputGroupInvalid: 'form-validator__input-group--invalid',
        inputInvalid: 'form-validator__input--invalid',
        errorsList: 'form-validator__errors',
        errorsListVisible: 'form-validator__errors--visible',
        errorsListItem: 'form-validator__error',
        validatedInput: '[data-validators]',
        maskedInput: '[data-mask-type]',
      },
      actions: {
        onValidityChange() { }
      }
    });
  }

  _autoBindPublicMethods() {
    bind(this.setState, this);
  }

  setState() {
    this._setState();
  }

  resetState() {
    this._setState();

    this.options.valid = null;
  }

  _emitValidityChange() {
    this.options.actions.onValidityChange(this.options.valid);
  }

  _buildErrorListItem(validationError) {
    return $('<li />', {
      class: this.options.classes.errorsListItem
    }).text(validationError);
  }

  _buildErrorListItems(validationErrors) {
    return map(validationErrors, bind(this._buildErrorListItem, this));
  }

  _getValidatedInputAttributes($input) {
    let name = $input.attr('name');
    let targetValidatorsForInput = $input.data('validators').split(',');
    let dependentInputName = $input.data('dependent');
    let $inputGroup = $input.parents(`.${this.options.classes.inputGroup}`);
    let $errorsList = $inputGroup.find(`.${this.options.classes.errorsList}`);

    return {
      name,
      targetValidatorsForInput,
      dependentInputName,
      $input,
      $inputGroup,
      $errorsList
    };
  }

  _getValueFromInput($input) {
    let isCheckbox = $input.attr('type') == 'checkbox';

    if (this._isPhoneValidator($input)) {
      return $input.intlTelInput('getNumber');
    }

    return isCheckbox ? $input.prop('checked') : $input.val();
  }

  _applyConstraintsToInput(name) {
    let validatedInput = this.options.validatedInputMap[name];
    let { $input, dependentInputName } = validatedInput.attributes;
    let values = { [name]: this._getValueFromInput($input) };
    let constraints = { [name]: validatedInput.constraints };

    if (dependentInputName) {
      let validatedDependentInput = this.options.validatedInputMap[dependentInputName];
      let validatedDependentInputValue = this._getValueFromInput(validatedDependentInput.attributes.$input);

      values[dependentInputName] = validatedDependentInputValue;
    }

    if (this._isPhoneValidator($input)) {
      this._setHiddenPhoneInputValue(name, values[name]);
    }

    return validate(values, constraints);
  }

  _isPhoneValidator($input) {
    return $input.data('validators').includes('phone');
  }

  _setHiddenPhoneInputValue(name, value) {
    $(`[name='${name.replace('phone', 'formatted_phone')}'][type=hidden]`).val(value);
  }

  _determineInputValidity(name) {
    let validationErrors = this._applyConstraintsToInput(name);

    validationErrors
      ? this._invalidateInput(name, validationErrors)
      : this._validateInput(name);

    this._determineValidityOfAllInputs();
  }

  _validateInput(name) {
    let validatedInput = this.options.validatedInputMap[name];
    let { $inputGroup, $errorsList } = validatedInput.attributes;
    let { classes } = this.options;

    validatedInput.valid = true;
    validatedInput.attributes.$input.removeClass(classes.inputInvalid);
    $inputGroup.removeClass(classes.inputGroupInvalid);
    $inputGroup.addClass(classes.inputGroupValid);
    $errorsList.empty().removeClass(classes.errorsListVisible);
  }

  _invalidateInput(name, validationErrors) {
    let validatedInput = this.options.validatedInputMap[name];
    let { $inputGroup, $errorsList } = validatedInput.attributes;
    let { classes } = this.options;
    let $errorListItems = this._buildErrorListItems(validationErrors);

    validatedInput.valid = false;
    validatedInput.attributes.$input.addClass(classes.inputInvalid);
    $inputGroup.addClass(classes.inputGroupInvalid);
    $inputGroup.removeClass(classes.inputGroupValid);
    $errorsList.addClass(classes.errorsListVisible).html($errorListItems);
  }

  _determineValidityOfAllInputs() {
    let isValid = every(this.options.validatedInputMap, ['valid', true]);

    // emit change event only if valid state has changed
    if (isValid !== this.options.valid) {
      this.options.valid = isValid;
      this._emitValidityChange();
    }
  }

  _buildConstraintsForValidatedInput(targetValidatorsForInput, dependentInputName) {
    return reduce(targetValidatorsForInput, (constraints, targetValidatorForInput) => {
      if (targetValidatorForInput === 'equality' && dependentInputName) {
        constraints[targetValidatorForInput] = dependentInputName;
      } else {
        constraints[targetValidatorForInput] = validators[targetValidatorForInput];
      }

      return constraints;
    }, {});
  }

  _mapValidatedInputsToValidators() {
    each(this.options.$validatedInputs, (input) => {
      let $input = $(input);
      let attributes = this._getValidatedInputAttributes($input);
      let constraints = this._buildConstraintsForValidatedInput(attributes.targetValidatorsForInput, attributes.dependentInputName);

      this.options.validatedInputMap[attributes.name] = {
        valid: false,
        constraints,
        attributes
      };
    });
  }

  _handleBlur(event) {
    let $input = $(event.currentTarget);
    let name = $input.attr('name');

    this._removeProcessingState(name);
    this._determineInputValidity(name);
  }

  _handleKeyup(event) {
    if (keyCodeUtil.isControl(event.which)) {
      return;
    }

    let $input = $(event.currentTarget);
    let name = $input.attr('name');

    this._processInput(name);
  }

  _setProcessingState(name) {
    let input = this.options.validatedInputMap[name];
    let { $inputGroup, $errorsList } = input.attributes;
    let { classes } = this.options;

    $inputGroup.removeClass(`${classes.inputGroupInvalid} ${classes.inputGroupValid}`);
    $errorsList.empty().removeClass(classes.errorsListVisible);
    $inputGroup.addClass(classes.inputGroupProcessing);
  }

  _removeProcessingState(name) {
    let input = this.options.validatedInputMap[name];
    let { $inputGroup } = input.attributes;
    let { classes } = this.options;

    $inputGroup.removeClass(classes.inputGroupProcessing);
  }

  _processInput(name) {
    this._setProcessingState(name);

    if (this.processingTimeout) {
      clearTimeout(this.processingTimeout);
    }

    this.processingTimeout = setTimeout(() => {
      this._removeProcessingState(name);
      this._determineInputValidity(name);
    }, this.options.debounceDelay);
  }

  _setState() {
    each(this.options.validatedInputMap, (validatedInput) => {
      this._determineInputValidity(validatedInput.attributes.name);
    });
  }

  _setDomDependentOptions() {
    this.options.$scope = $(this.options.selector);
    this.options.$validatedInputs = this.options.$scope.find(this.options.classes.validatedInput);
    this.options.$maskedInputs = this.options.$scope.find(this.options.classes.maskedInput);
  }

  _setPhoneValidators() {
    const phoneValidationSelector = '[data-validators*="phone"]';

    this.$phoneInputs = $(phoneValidationSelector).map(function(_, phoneInput) {
      return $(phoneInput).intlTelInput({
        preferredCountries: ['CH', 'AU', 'BE', 'CA', 'DE', 'FR', 'NL', 'GB', 'US'],
        hiddenInput: 'formatted_phone',
        utilsScript: 'vendor/utils',
        initialCountry: this.dataset['default'] || undefined,
      });
    });
  }

  _attach() {
    let { $scope, classes } = this.options;

    $scope.on({
      'change.step-validator:input-change': bind(this._handleBlur, this),
      'blur.step-validator:input-blur': bind(this._handleBlur, this),
      'keyup.step-validator:input-keyup': bind(this._handleKeyup, this)
    }, classes.validatedInput);
  }

  _possiblyMaskInputs() {
    each(this.options.$maskedInputs, (input) => {
      let $input = $(input);
      const maskType = $input.data('mask-type');

      const maskFn = masking.byType[maskType];
      if (maskFn) {
        maskFn(input);
      }
    });
  }

  init() {
    this._setDomDependentOptions();
    this._mapValidatedInputsToValidators();
    this._setPhoneValidators();
    this._attach();
    this._possiblyMaskInputs();
  }
}

export default FormValidatorComponent;
