import $ from 'jquery';
import merge from 'lodash/merge';
import bind from 'lodash/bind';
import ModalComponent from 'components/modal';
import ButtonComponent from 'components/button';
import i18n from 'utils/i18n';
import IosAppUtil from 'utils/ios_app';

class LoginModalComponent extends ModalComponent {
  constructor() {
    super();

    this.components = {
      submitButton: new ButtonComponent()
    };
  }

  static get defaults() {
    let defaults = merge({}, super.defaults, {
      trigger: '.login-modal__trigger',
      selector: '.login-modal',
      errorTimeout: 2500,
      successTimeout: 1250,
      submitTimeout: 1000,
      classes: {
        form: 'login-modal__form',
        submitButton: 'login-modal__submit-button',
        focusedField: 'login-modal__focused-field',
        errors: 'login-modal__errors',
        errorsVisible: 'login-modal__errors--visible',
        success: 'login-modal__success',
        successVisible: 'login-modal__success--visible'
      }
    });

    return Object.freeze(defaults);
  }

  _reloadWindow() {
    window.location.reload();
  }

  _open(event) {
    super._open(event);

    this.options.$focusedField.focus();
  }

  _close() {
    super._close();

    this._clearSuccess();
    this._clearErrors();
    this.components.submitButton.removeStatefulClasses();
  }

  _setPreloadState() {
    this.components.submitButton.setLoadingState();
  }

  _setSuccess() {
    let {
      $success,
      classes
    } = this.options;
    let successMessage = i18n.t('components.login_modal.success_message');

    $success.addClass(classes.successVisible);
    $success.html(successMessage);
  }

  _clearSuccess() {
    let {
      $success,
      classes
    } = this.options;

    $success.removeClass(classes.successVisible);
    $success.empty();
  }

  _setErrors(errorMessage) {
    let {
      $errors,
      classes
    } = this.options;

    $errors.addClass(classes.errorsVisible);
    $errors.html(errorMessage);
  }

  _clearErrors() {
    let {
      $errors,
      classes
    } = this.options;

    $errors.removeClass(classes.errorsVisible);
    $errors.empty();
  }

  _onSuccess(response) {
    let { email } = response;

    this.components.submitButton.setSuccessState();

    this._clearErrors();
    this._setSuccess();

    IosAppUtil.userSignedIn(email);

    setTimeout(
      bind(this._reloadWindow, this),
      this.options.successTimeout
    );
  }

  _onError(xhr) {
    let { submitButton } = this.components;
    let errorMessage     = JSON.parse(xhr.responseText).error;

    this._setErrors(errorMessage);
    submitButton.setErrorState();

    setTimeout(() => {
      submitButton.removeStatefulClasses();
    }, this.options.errorTimeout);
  }

  _onFormSubmit(event) {
    event.preventDefault();

    let $form  = $(event.currentTarget);
    let url    = $form.attr('action');
    let data   = $form.serialize();

    this._setPreloadState();

    setTimeout(() => {
      $.ajax({
        url,
        data,
        method: 'POST',
        dataType: 'json'
      }).then(
        bind(this._onSuccess, this),
        bind(this._onError, this)
      );
    }, this.options.submitTimeout);
  }

  _setDomDependentOptions() {
    super._setDomDependentOptions();

    let {
      $scope,
      classes
    } = this.options;

    this.options.$form         = $scope.find(`.${classes.form}`);
    this.options.$submitButton = $scope.find(`.${classes.submitButton}`);
    this.options.$focusedField = $scope.find(`.${classes.focusedField}`);
    this.options.$errors       = $scope.find(`.${classes.errors}`);
    this.options.$success      = $scope.find(`.${classes.success}`);
  }

  _attach() {
    super._attach();

    this.options.$form.on('submit.login-modal:form-submit', bind(this._onFormSubmit, this));
  }

  init(options = {}) {
    super.init(options);

    this.components.submitButton.init({
      selector: `.${this.options.classes.submitButton}`
    });
  }
}

export default LoginModalComponent;
