import en from 'locales/en.json';
import enUS from 'locales/en-US.json';
import fr from 'locales/fr.json';
import frCA from 'locales/fr-CA.json';
import frFR from 'locales/fr-FR.json';
import de from 'locales/de.json';
import deCasual from 'locales/de-x-du.json';
import es from 'locales/es.json';
import it from 'locales/it.json';
import ja from 'locales/ja.json';
import zh from 'locales/zh.json';
import zhCN from 'locales/zh-CN.json';

export default {
  'en': en,
  'en-US': enUS,
  'fr': fr,
  'fr-FR': frFR,
  'fr-CA': frCA,
  'de': de,
  'de-x-du': deCasual,
  'es': es,
  'it': it,
  'ja': ja,
  'zh': zh,
  'zh-CN': zhCN
};
