import $ from 'jquery';
import merge from 'lodash/merge';
import bind from 'lodash/bind';
import cookieHelper from 'utils/cookies';

class CookieNotificationComponent {
  constructor() {
    this._autoBindPublicMethods();
  }

  static get defaults() {
    return Object.freeze({
      selector: '.cookie-notification',
      classes: {
        hidden: 'cookie-notification--hidden',
        dismiss: 'cookie-notification__dismiss',
        learnMore: 'cookie-notification__learn-more'
      }
    });
  }

  _autoBindPublicMethods() {
    bind(this._addTransparency, this);
  }

  _checkCookie() {
    if (!cookieHelper.hasItem('acceptedCookieUse') && !$('body').hasClass('origin-ios-app')) {
      this.options.$scope.removeClass(this.options.classes.hidden);
    }
    if (!cookieHelper.hasItem('acceptedCookieUse') && !$('body').hasClass('origin-android-app')) {
      this.options.$scope.removeClass(this.options.classes.hidden);
    }
  }

  _setDomDependentOptions() {
    this.options.$scope = $(this.options.selector);
  }

  _attach() {
    this.options.$scope.on(
      'click.cookie-notification:dismiss',
      `.${this.options.classes.dismiss}`,
      bind(this._dismiss, this)
    );
  }

  _dismiss() {
    cookieHelper.setItem('acceptedCookieUse', true, Infinity, '/', location.host);
    this._addTransparency();
  }

  _addTransparency() {
    this.options.$scope.addClass(this.options.classes.hidden);
  }

  init(options = {}) {
    this.options = merge({}, this.constructor.defaults, options);
    this._setDomDependentOptions();
    this._attach();
    this._checkCookie();
  }
}

export default CookieNotificationComponent;
