import $ from 'jquery';
import merge from 'lodash/merge';
import bind from 'lodash/bind';
import { APPLICATION_CHROME_OFFSET_TOP } from 'utils/constants.js.erb';

class AccordionComponent {
  constructor() {
    this._autoBindPublicMethods();
  }

  static get defaults() {
    return Object.freeze({
      selector: '.accordion',
      scrollToOnExpand: true,
      scrollToDuration: 200,
      offsetBuffer: APPLICATION_CHROME_OFFSET_TOP,
      classes: {
        expanded: 'accordion--expanded',
        trigger: 'accordion__trigger'
      },
      actions: {
        onExpand() {},
        onCollapse() {}
      }
    });
  }

  _autoBindPublicMethods() {
    bind(this.scrollTo, this);
  }

  _emitCollapsedState() {
    this.options.actions.onCollapse();
  }

  _emitExpandedState() {
    this.options.actions.onExpand();
  }

  scrollTo() {
    this._scrollTo();
  }

  _setDomDependentOptions() {
    this.options.$scope = $(this.options.selector);
  }

  _scrollTo() {
    let offset = this.options.$scope.offset().top - this.options.offsetBuffer;

    $('html,body').animate({ scrollTop: offset }, this.options.scrollToDuration);
  }

  _collapse($clickedNode) {
    $clickedNode.removeClass(this.options.classes.expanded);
    this._emitCollapsedState();
  }

  _expand($clickedNode) {
    $clickedNode.addClass(this.options.classes.expanded);
    this._emitExpandedState();

    if (this.options.scrollToOnExpand) {
      this._scrollTo();
    }
  }

  _handleTriggerClick(event) {
    event.preventDefault();

    const $clickedNode = $(event.target).closest(this.options.selector);
    const isExpanded = $clickedNode.hasClass(this.options.classes.expanded);

    isExpanded
      ? this._collapse($clickedNode)
      : this._expand($clickedNode);
  }

  _attach() {
    let { $scope, classes } = this.options;

    $scope.on(
      'click.accordion:trigger-click',
      `.${classes.trigger}`,
      bind(this._handleTriggerClick, this)
    );
  }

  init(options = {}) {
    this.options = merge({}, this.constructor.defaults, options);

    this._setDomDependentOptions();
    this._attach();
  }
}

export default AccordionComponent;
