import extend from 'lodash/extend';
import Logger from 'utils/logger';
import CoreTopBarComponent from 'components/core_top_bar';
import LazyLoaderComponent from 'components/lazy_loader';
import CookieNotificationComponent from 'components/cookie_notification';

class ApplicationController {
  constructor(options = {}) {
    extend(this, options);

    this.components = {
      coreTopBar: new CoreTopBarComponent(),
      lazyLoader: new LazyLoaderComponent(),
      cookieNotification: new CookieNotificationComponent()
    };
  }

  init(options = {}) {
    let {
      coreTopBar,
      lazyLoader,
      cookieNotification
    } = this.components;

    coreTopBar.init(options.coreTopBar);
    lazyLoader.init(options.lazyLoader);
    cookieNotification.init(options.cookieNotification);

    Logger.log('DEBUG:', this);
  }
}

export default ApplicationController;
