import $ from 'jquery';

let AndroidAppUtil = {
  isAndroidApp() {
    return $('body').hasClass('origin-android-app');
  },

  showBrowserBackButton() {
    if(this.isAndroidApp()) {
      setTimeout( () => {window.ReactNativeWebView.postMessage('showBrowserBack');}, 200);
    }
  },

  showAppBackButton() {
    if(this.isAndroidApp()) {
      setTimeout( () => {window.ReactNativeWebView.postMessage('showAppBack');}, 200);
    }
  },

  initPage() {
    // default behavior is to show the browser back button and not the app back button. Body classes can
    // be added to a view to override these defaults.
    if($('body').hasClass('webview-with-browser-back-button')) {
      this.showBrowserBackButton();
    } else {
      this.showAppBackButton();
    }
  },
};

export default AndroidAppUtil;
