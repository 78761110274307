import extend from 'lodash/extend';
import ApplicationController from 'core/controllers/application';
import JumplinksComponent from 'components/jumplinks';
import ContentRevealerComponent from 'components/content_revealer';
import ImageGalleryComponent from 'components/image_gallery';
import DatePickerComponent from 'components/date_picker';
import SearchInputComponent from 'components/search_input';
import FormValidatorComponent from 'components/form_validator';

class StaticStyleguideController extends ApplicationController {
  constructor(options = {}) {
    super(options);

    extend(this.components, {
      jumplinks: new JumplinksComponent(),
      contentRevealer: new ContentRevealerComponent(),
      imageGallery: new ImageGalleryComponent(),
      datePicker: new DatePickerComponent(),
      searchInput: new SearchInputComponent(),
      formValidator: new FormValidatorComponent()
    });
  }

  init() {
    super.init();

    this.components.jumplinks.init();
    this.components.contentRevealer.init();
    this.components.imageGallery.init();
    this.components.datePicker.init();
    this.components.searchInput.init({ selector: '.styleguide__search-input' });
  }
}

export default StaticStyleguideController;
