import $ from 'jquery';
import merge from 'lodash/merge';
import bind from 'lodash/bind';
import KeyCodeUtil from 'utils/key_code';
import ButtonComponent from 'components/button';

class EmailCaptureComponent {
  constructor() {
    this.components = {
      button: new ButtonComponent()
    };
  }

  static get defaults() {
    const namespace = 'email-capture';

    return Object.freeze({
      selector: `.${namespace}`,
      method: 'POST',
      url: '',
      errorTimeout: 2500,
      submitTimeout: 1000,
      classes: {
        success: `${namespace}--success`,
        error: `${namespace}--error`,
        accept: `${namespace}__accept`,
        button: `${namespace}__button`,
        email: `${namespace}__email`,
        url: `${namespace}__url`,
        regionId: `${namespace}__region-id`,
        resort: `${namespace}__resort`,
      },
      actions: {}
    });
  }

  _setPreLoadState() {
    this.components.button.setLoadingState();
  }

  _setErrorState() {
    const { $scope, classes } = this.options;

    $scope.addClass(classes.error);
  }

  _setValidationState() {
    const { $scope, classes } = this.options;

    $scope.removeClass(classes.error);
    $scope.removeClass(classes.success);
    this._hideFieldErrors();
    this._setButtonState();
  }

  _setButtonState() {
    const { $button, $accept, $email } = this.options;

    if($accept.prop('checked') && this._validateEmail($email.val())) {
      $button.removeAttr('disabled');
    }
    else {
      $button.attr('disabled', 'disabled');
    }
  }

  _onSuccess() {
    const { $scope, classes, $email } = this.options;

    $scope.addClass(classes.success);
    $email.val('');
    this.components.button.removeStatefulClasses();
    this._setButtonState(); 
  }

  _onError() {
    const { button } = this.components;

    button.setErrorState();
    this._setErrorState();

    setTimeout(() => {
      button.removeStatefulClasses();
    }, this.options.errorTimeout);
  }

  _postEmail() {
    const { $email, url, method, token, resort, regionId } = this.options;
    const email = $email.val();

    return $.ajax({
      url,
      method,
      headers: { 'X-CSRF-Token': token },
      data: { email, resort, 'region_id': regionId }
    });
  }

  _apply(event) {
    if (event) {
      event.preventDefault();
    }

    if(this.components.button.isDisabled()) {
      this._displayFieldErrors(event);
    }
    else {
      this._setValidationState();
      this._setPreLoadState();

      setTimeout(() => {
        this._postEmail().then(
          bind(this._onSuccess, this),
          bind(this._onError, this)
        );
      }, this.options.submitTimeout);
    }
  }

  _displayFieldErrors(event) {
    const { $accept, $acceptError, $email, $emailError } = this.options;
    const visibleClass = 'form__field-error--visible';
    let errorsDisplayed = false;

    if(!$accept.prop('checked')) {
      $acceptError.addClass(visibleClass);
      errorsDisplayed = true;
    }
    else if(!this._validateEmail($email.val())) {
      $emailError.addClass(visibleClass);
      errorsDisplayed = true;
    }

    if(errorsDisplayed) {
      event.stopPropagation();
      $(document).on(
        'click.email-capture',
        bind(this._hideFieldErrors, this)
      );
    }
  }

  _hideFieldErrors() {
    const { $acceptError, $emailError } = this.options;
    const visibleClass = 'form__field-error--visible';

    $acceptError.removeClass(visibleClass);
    $emailError.removeClass(visibleClass);

    $(document).off(
      'click.email-capture',
      bind(this._hideFieldErrors, this)
    );
  }

  _initButton() {
    this.components.button.init({
      selector: `.${this.options.classes.button}`,
      actions: {
        onClick: bind(this._apply, this)
      }
    });
  }

  _handleAcceptClick() {
    const { $accept, $email } = this.options;

    this._setValidationState();
    if($accept.prop('checked')) $email.focus();
  }

  _handleKeydown(event) {
    const shouldSubscribe = KeyCodeUtil.isEqual(event.which, 'ENTER');

    if (shouldSubscribe) {
      event.preventDefault();
      this._apply();
    }
  }

  _handleInput() {
    this._setValidationState();
  }

  _attach() {
    const { $scope, classes } = this.options;

    $scope.on(
      'keydown.email-capture:keydown',
      `.${classes.email}`,
      bind(this._handleKeydown, this)
    );

    $scope.on(
      'input.email-capture:input',
      `.${classes.email}`,
      bind(this._handleInput, this)
    );

    $scope.on(
      'click.email-capture:click',
      `.${classes.accept}`,
      bind(this._handleAcceptClick, this)
    );
  }

  _validateEmail(value) {
    // This is the regex used for client-side email address validation elsewhere.
    return /^.+@.+\.(.+){2,}$/.test(value);
  }

  _setDomDependentOptions() {
    this.options.$scope        = $(this.options.selector);
    this.options.$accept       = this.options.$scope.find(`.${this.options.classes.accept}`);
    this.options.$email        = this.options.$scope.find(`.${this.options.classes.email}`);
    this.options.$acceptError  = this.options.$scope.find(`.${this.options.classes.accept}--error`);
    this.options.$emailError   = this.options.$scope.find(`.${this.options.classes.email}--error`);
    this.options.$button       = this.options.$scope.find(`.${this.options.classes.button}`);
    this.options.url           = this.options.$scope.find(`.${this.options.classes.url}`).val();
    this.options.regionId      = this.options.$scope.find(`.${this.options.classes.regionId}`).val();
    this.options.resort        = this.options.$scope.find(`.${this.options.classes.resort}`).val();
    this.options.token         = $('meta[name="csrf-token"]').attr('content');
  }

  _setup() {
    this._setDomDependentOptions();
    this._attach();
    this._initButton();
    this._setValidationState();
  }

  init(options = {}) {
    this.options = merge({}, this.constructor.defaults, options);

    this._setup();
  }
}

export default EmailCaptureComponent;
