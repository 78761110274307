import $ from 'jquery';
import merge from 'lodash/merge';
import bind from 'lodash/bind';
import throttle from 'lodash/throttle';
import keyCodeUtil from 'utils/key_code';

class ModalComponent {
  constructor() {
    this._autoBindPublicMethods();
  }

  static get defaults() {
    return Object.freeze({
      trigger: '.modal__trigger',
      selector: '.modal',
      throttleDelay: 200,
      classes: {
        visible: 'modal--visible',
        overflowHidden: 'modal__overflow-hidden',
        backdrop: 'modal__backdrop',
        content: 'modal__content',
        titleCloseTrigger: 'modal__title-close-trigger',
        actionCloseButton: 'modal__close'
      },
      actions: {
        onOpen() {},
        onClose() {}
      }
    });
  }

  open() {
    this._open();
  }

  close() {
    this._close();
  }

  _autoBindPublicMethods() {
    bind(this.open, this);
    bind(this.close, this);
  }

  _emitOpen() {
    this.options.actions.onOpen();
  }

  _emitClose() {
    this.options.actions.onClose();
  }

  _attachKeyUpHandlers() {
    this.options.$document.on(
      'keyup.modal:keyup-event',
      throttle(
        bind(this._handleKeyUp, this),
        this.options.throttleDelay
      )
    );
  }

  _detachKeyUpHandlers() {
    this.options.$document.off('keyup.modal:keyup-event');
  }

  _open(event) {
    if (event) { event.preventDefault(); }

    this._emitOpen();

    let $scope = this.options.$scope;
    if (event) {
      $scope = $(this.options.$scope.get().find($el =>
        $el.matches(event.currentTarget.dataset.modal)
      ));
    }

    if ($scope.length > 0) {
      $scope.addClass(this.options.classes.visible);

      this.options.$body.addClass(this.options.classes.overflowHidden);

      this._attachKeyUpHandlers();
    }
  }

  _close() {
    this._emitClose();

    this.options.$scope.removeClass(this.options.classes.visible);
    this.options.$body.removeClass(this.options.classes.overflowHidden);

    this._detachKeyUpHandlers();
  }

  _handleKeyUp(event) {
    let shouldCloseModal = keyCodeUtil.isEqual(event.which, 'ESC');

    if (shouldCloseModal) {
      this._close();
    }
  }

  _stopPropagation(event) {
    event.stopImmediatePropagation();
  }

  _setDomDependentOptions() {
    this.options.$document = $(document);
    this.options.$body     = $('html,body');
    this.options.$trigger  = $(this.options.trigger);

    const selectors        = [...this.options.$trigger].map($trigger => $trigger.dataset.modal);
    this.options.$scope    = $(selectors.join(', '));
  }

  _attach() {
    let {
      $scope,
      $trigger,
      classes
    } = this.options;

    $trigger.on('click.modal:trigger-click', bind(this._open, this));

    $scope.on(
      'click.modal:close',
      `
        .${classes.backdrop},
        .${classes.titleCloseTrigger},
        .${classes.actionCloseButton}
      `,
      bind(this._close, this)
    );

    $scope.on(
      'click.modal:stop-propagation',
      `.${classes.content}`,
      bind(this._stopPropagation, this)
    );
  }

  init(options = {}) {
    this.options = merge({}, this.constructor.defaults, options);

    this._setDomDependentOptions();
    this._attach();
  }
}

export default ModalComponent;
