import merge from 'lodash/merge';
import GiftCardStripeComponent from 'components/gc_stripe';

class GiftCardPaymentFormComponent {
  constructor() {
    this.components = {
      stripeCardElement: new GiftCardStripeComponent(),
    };
  }

  static get defaults() {
    return Object.freeze({
      selector: '.giftcards-payment-form',
      classes: {
        formContainer: 'giftcards__form-container',
        errorDiv: 'error-div',
        errorMessage: 'error-div__message',
        errorVisible: 'visible',
        submitting: 'submitting',
        incompleteInput: 'giftcards-payment-form__incomplete_input',
      },
    });
  }

  _stopLoading() {
    this.options.formContainer.classList.remove(this.options.classes.submitting);
  }

  _startLoading() {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    this.options.formContainer.classList.add(this.options.classes.submitting);
  }

  _isValid() {
    const { form, classes } = this.options;

    let valid = true;
    Array.from(form.querySelectorAll('[required]')).forEach(input => {
      if (!input.checkValidity()) {
        input.classList.add(classes.incompleteInput);
        valid = false;
      }
    });

    return valid;
  }

  _handleFormSubmit(e, elements) {
    const { stripe } = this.components.stripeCardElement;
    const { form } = this.options;

    e.preventDefault();
    if (!this._isValid()) { return; }
    this._startLoading();

    const firstName = form.querySelector('#billing_first_name');
    const lastName = form.querySelector('#billing_last_name');
    const zip = document.getElementsByName('postal')[0];

    const additionalData = {
      billing_details: {
        name: (firstName && lastName) ? (firstName.value + ' ' + lastName.value) : undefined,
        address: { postal_code: zip ? zip.value : undefined } ,
      }
    };

    stripe.createPaymentMethod('card', elements[0], additionalData).then( result => {

      if (result.paymentMethod) {
        const stripeTokenNode = document.createElement('input');
        stripeTokenNode.setAttribute('type', 'hidden');
        stripeTokenNode.setAttribute('name', 'stripeToken');
        stripeTokenNode.setAttribute('value', result.paymentMethod.id);
        form.appendChild(stripeTokenNode);

        const billing_zip = document.createElement('input');
        billing_zip.setAttribute('type', 'hidden');
        billing_zip.setAttribute('name', 'billing_zip');
        billing_zip.setAttribute('value', result.paymentMethod.billing_details.address.postal_code);
        form.appendChild(billing_zip);

        const billing_country = document.createElement('input');
        billing_country.setAttribute('type', 'hidden');
        billing_country.setAttribute('name', 'billing_country');
        billing_country.setAttribute('value', result.paymentMethod.billing_details.address.country);
        form.appendChild(billing_country);

        form.submit();

      } else {
        this._handleFormSubmitError(result.error);
      }
    });
  }

  _handleFormSubmitError(error) {
    const { errorDiv, errorMessage, classes } = this.options;

    errorDiv.classList.add(classes.errorVisible);
    errorMessage.innerText = error.message;
    setTimeout(() => this._stopLoading(), 500);
  }

  _handleCardError(event, savedErrors, idx) {
    const { errorDiv, errorMessage, classes } = this.options;

    if (event.error) {
      errorDiv.classList.add(classes.errorVisible);
      savedErrors[idx] = event.error.message;
      errorMessage.innerText = event.error.message;
    } else {
      savedErrors[idx] = null;
      let nextError = Object.keys(savedErrors).sort().reduce(
        (maybeFoundError, key) => (maybeFoundError || savedErrors[key]), null
      );
      nextError ? errorMessage.innerText = nextError : errorDiv.classList.remove(classes.errorVisible);
    }
  }

  _attachCardListener() {
    const savedErrors = {};
    const elements = [this.components.stripeCardElement.card];

    elements.forEach((element, idx) => {
      element.on('change', (event) => this._handleCardError(event, savedErrors, idx) );
    });
  }

  _attachFormListener() {
    const elements = [this.components.stripeCardElement.card];
    this.options.form.addEventListener('submit', (e) => this._handleFormSubmit(e, elements));
  }

  _attachCurrencySelectListener() {
    const { currencySelect } = this.options;
    const { stripeCardElement } = this.components;

    currencySelect.addEventListener('change', () => {
      stripeCardElement._changeAPIKey(currencySelect.value);
      this._attachCardListener();
      this._attachFormListener();
    });
  }

  _attach() {
    this._attachCardListener();
    this._attachFormListener();
    this._attachCurrencySelectListener();
  }

  _setDomDependentOptions() {
    const { formContainer, errorDiv, errorMessage } = this.options.classes;

    this.options.scope = document.querySelector(this.options.selector);

    this.options.currencySelect    = document.getElementsByName('currency_code')[0];
    this.options.formContainer     = document.querySelector(`.${formContainer}`);
    this.options.form              = this.options.formContainer.querySelector('form');
    this.options.errorDiv          = this.options.form.querySelector(`.${errorDiv}`);
    this.options.errorMessage      = this.options.errorDiv.querySelector(`.${errorMessage}`);
  }

  init(options = {}) {
    this.options = merge({}, this.constructor.defaults, options);
    this.components.stripeCardElement.init();
    this._setDomDependentOptions();
    this._attach();
    this._stopLoading();
  }
}

export default GiftCardPaymentFormComponent;
