import $ from 'jquery';
import merge from 'lodash/merge';
/*eslint-disable no-unused-vars*/
import magnific from 'vendor/magnific';
/*eslint-enable no-unused-vars*/

class ImageGalleryComponent {
  constructor() {}

  static get defaults() {
    return Object.freeze({
      selector: '.image-gallery',
      widget: {
        delegate: 'a',
        type: 'image',
        mainClass: 'image-gallery',
        preloader: true,
        fixedContentPos: true,
        closeMarkup: '<i title="%title%" type="button" class="mfp-close fas fa-times"></i>',
        image: {
          markup: '<div class="mfp-figure">' +
                    '<div class="mfp-top-bar">' +
                      '<div class="mfp-counter header--4"></div>' +
                      '<div class="mfp-close"></div>' +
                    '</div>' +
                    '<div class="mfp-img"></div>' +
                  '</div>',
          titleSrc: 'title',
          verticalFit: true
        },
        tLoading: '...',
        gallery: {
          enabled: true,
          navigateByImgClick: true,
          preload: 0,
          arrowMarkup: '<i title="%title%" class="mfp-arrow mfp-arrow-%dir% fas fa-chevron-%dir%"></i>',
          tPrev: 'Previous (Left arrow key)',
          tNext: 'Next (Right arrow key)',
          tCounter: '<span class="mfp-counter">%curr% / %total%</span>'
        },
        callbacks: {}
      }
    });
  }

  initWidget() {
    const $instances = $(this.options.selector);
    const $widgets = [...$instances].map($el => $($el));

    $widgets.forEach($widget => $widget.magnificPopup(this.options.widget));
  }

  init(options) {
    this.options = merge({}, this.constructor.defaults, options);

    this.initWidget();
  }
}

export default ImageGalleryComponent;
