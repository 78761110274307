import moment from 'moment';
import validate from 'validate\.js';
import i18n from 'utils/i18n';
import { isValidNumber } from 'libphonenumber-js';

/* configure validate plugin */
validate.options = { format: 'flat' };

/* write custom validators */
validate.validators.birthDate = (value) => {
  let isValid = moment(value, i18n.l('date.birthDate'), true).isValid();

  return isValid ? undefined : `^${i18n.t('validators.date')}`;
};

validate.validators.phone = (value) => {
  return isValidNumber(value) ? undefined : `^${i18n.t('validators.phone')}`;
};

validate.validators.creditCardExpirationDate = (value) => {
  let isValid = moment(value, i18n.l('date.creditCardExpirationDate'), true).isValid();

  return isValid ? undefined : `^${i18n.t('validators.creditCardExpirationDate')}`;
};

validate.validators.checked = (value) => {
  return value ? undefined : `^${i18n.t('validators.checked')}`;
};

validate.validators.termsOfUseChecked = (value) => {
  return value ? undefined : `^${i18n.t('validators.termsOfUseChecked')}`;
};

validate.validators.waiverChecked = (value) => {
  return value ? undefined : `^${i18n.t('validators.waiverChecked')}`;
};

/* make custom validators available and modify existing validators */
let validators = {
  email: {
    message: `^${i18n.t('validators.email')}`
  },
  phone: {
    message: `^${i18n.t('validators.phone')}`
  },
  presence: {
    message: `^${i18n.t('validators.presence')}`
  },
  numericality: {
    strict: true,
    message: `^${i18n.t('validators.numericality')}`
  },
  checked: {
    presence: true,
    inclusion: {
      within: [true]
    }
  },
  termsOfUseChecked: {
    presence: true,
    inclusion: {
      within: [true]
    }
  },
  waiverChecked: {
    presence: true,
    inclusion: {
      within: [true]
    }
  },
  foo: true,
  birthDate: true,
  creditCardExpirationDate: true
};

export { validate, validators };
