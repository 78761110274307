import extend from 'lodash/extend';
import ApplicationController from 'core/controllers/application';
import ContentRevealerComponent from 'components/content_revealer';
import ImageGalleryComponent from 'components/image_gallery';
import SearchInputComponent from 'components/search_input';
import DfpComponent from 'components/dfp';

class ResortsTrailMapsIndexController extends ApplicationController {
  constructor(options = {}) {
    super(options);

    extend(this.components, {
      contentRevealer: new ContentRevealerComponent(),
      imageGallery: new ImageGalleryComponent(),
      searchInput: new SearchInputComponent(),
      dfp: new DfpComponent()
    });
  }

  init() {
    super.init();

    this.components.contentRevealer.init();
    this.components.imageGallery.init({
      selector: '.resort-index-trail-maps-index__trail-maps-list',
      widget: {
        delegate: '.resorts-trail-maps-index__trail-map-link--wrap'
      }
    });
    this.components.searchInput.init({ selector: '.resorts-trail-maps-index__sidebar__search-input' });
    this.components.dfp.init();
  }
}

export default ResortsTrailMapsIndexController;
