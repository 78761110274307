import ApplicationController from 'core/controllers/application';

class StaticErrorController extends ApplicationController {
  init() {
    super.init({
      coreTopBar: {
        hideSearchInput: true
      }
    });
  }
}

export default StaticErrorController;
