import IMask from 'imask';
import moment from 'moment';
import i18n from 'utils/i18n';

const getDateBlocks = (thisYear) => ({
  YYYY: {
    mask: IMask.MaskedRange,
    from: thisYear - 115,
    to: thisYear,
  },
  MM: {
    mask: IMask.MaskedRange,
    from: 1,
    to: 12
  },
  DD: {
    mask: IMask.MaskedRange,
    from: 1,
    to: 31
  },
});

const masking = {
  byType: {
    birthDate: (input) => {
      const mask = i18n.l('date.birthDate');

      IMask(input, {
        mask: Date,
        pattern: mask,
        format: (date) => moment(date).format(mask),
        parse: (str) => moment(str, mask),
        blocks: getDateBlocks(new Date().getFullYear()),
      });
    },
  }
};


export { masking };