import $ from 'jquery';
import bind from 'lodash/bind';
import merge from 'lodash/merge';
import { APPLICATION_CHROME_OFFSET_TOP } from 'utils/constants.js.erb';

class JumplinksComponent {
  constructor() {}

  static get defaults() {
    return Object.freeze({
      selector: '.jumplinks',
      classes: {
        jumplink: 'jumplinks__jumplink'
      },
      offsetBuffer: APPLICATION_CHROME_OFFSET_TOP,
      duration: 200
    });
  }

  scrollToElement(event) {
    event.preventDefault();

    let id     = $(event.currentTarget).data('anchor');
    let offset = $(id).offset().top - this.options.offsetBuffer;

    $('html, body').animate({ scrollTop: offset }, this.options.duration);
  }

  attach() {
    let $scope = $(this.options.selector);

    $scope.on(
      'click.jumplinks:click',
      `.${this.options.classes.jumplink}`,
      bind(this.scrollToElement, this)
    );
  }

  init(options) {
    this.options = merge({}, this.constructor.defaults, options);

    this.attach();
  }
}

export default JumplinksComponent;
