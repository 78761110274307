import extend from 'lodash/extend';
import ApplicationController from 'core/controllers/application';
import GoogleMapComponent from 'components/google_map';
import DfpComponent from 'components/dfp';
import SearchInputComponent from 'components/search_input';

class RegionsMapsShowController extends ApplicationController {
  constructor(options = {}) {
    super(options);

    extend(this.components, {
      googleMap: new GoogleMapComponent(),
      dfp: new DfpComponent(),
      searchInput: new SearchInputComponent()
    });
  }

  init() {
    super.init();

    this.components.googleMap.init();
    this.components.searchInput.init({ selector: '.regions-map__sidebar__search-input' });
    this.components.dfp.init();
  }
}

export default RegionsMapsShowController;
